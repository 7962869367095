<main class="bg-login w-screen h-screen relative overflow-auto">
  <figure class="icon-clic hidden md:block">
    <img src="../../../assets/img/logo.png" alt="logo bayport" class="w-3rem" />
  </figure>
  <article class="w-full h-full absolute top-0 left-0 grid">
    <div class="relative bg-blue-gradient m-auto w-11 sm:w-5 lg:w-4 p-5 sm:border-1 border-round border-sky-200"
    style="background: transparent linear-gradient(180deg, var(--unnamed-color-00405f) 0%, var(--unnamed-color-0f113e) 100%) 0% 0% no-repeat padding-box;
    border: 0.5px solid var(--unnamed-color-81daff);
    background: transparent linear-gradient(180deg, #00405F 0%, #0F113E 100%) 0% 0% no-repeat padding-box;
    border: 0.5px solid #81DAFF;
    border-radius: 4px;">
      <figure class="md:hidden icon-clic-2">
        <img src="../../../assets/img/logo-2.svg" alt="logo bayport" class="w-4rem mb-2" />
      </figure>
      <form [formGroup]="recForm" (ngSubmit)="recuperarPassword()" class="mt-0">
        <h2 class=" mb-2 font-bold text-xl text-white">Olvido de contraseña</h2>
        <p class="mb-4 text-sm text-white fw-semibold" style="font-size: 16px !important;">
          No te preocupes todos olvidamos nuestras contraseñas. <br>
          Ingresa tu usuario y sigue las instrucciones.
        </p>
        <label htmlFor="user" class="font-bold text-white">
          Usuario
        </label>
        <input pInputText formControlName="user" placeholder="*número de cédula" maxlength="15"
          class="max-w-none w-full mb-2" />
        <small *ngIf="recForm.get('user').hasError('required') && recForm.get('user').touched"
          class="validation-message">
          El usuario es requerido
        </small>
        <div *ngIf="recForm.get('user').value !== ''">
          <small *ngIf="recForm.controls.user.hasError('pattern')" class="validation-message">
            El campo solo acepta <strong>números</strong></small>
          <small *ngIf="error !== null" class="validation p-1 font-bold">{{ error }}</small>
        </div>
        <footer class="flex mt-4 justify-content-center">
          <button pButton label="Recuperar Contraseña" [disabled]="!this.recForm.valid" type="submit"
            class=" py-2 px-6 text-gray-900 bg-sky-200 border-none"></button>
        </footer>
      </form>
      <div class="flex justify-content-center mt-3">
        <a routerLink="/" class="flecha-izquierda enlace text-sky-200">
          <i class="palabra-animada fas fa-arrow-left"></i>
          Volver
        </a>
      </div>
    </div>
  </article>
</main>
