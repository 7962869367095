<div class="d-cotainer">
  <h2>
    <span><strong>Rechazar oferta de crédito</strong></span>
  </h2>
  <div class="txt-dialog">
    <mat-dialog-content class="mat-typography container-body">
      <p>¿Estás seguro que deseas rechazar la oferta de crédito? por favor selecciona un motivo:</p>

      <div class="input-container">
        <mat-form-field appearance="outline">
          <mat-label>Selecionar motivo</mat-label>
          <mat-select disableRipple [(ngModel)]="motivo">
            <mat-option *ngFor="let motiv of motivosRechazo" [value]="motiv.codigo">{{motiv.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="example-form-field" appearance="outline">
          <mat-label>Observaciones</mat-label>
          <input matInput type="text" [(ngModel)]="observaciones">
        </mat-form-field>
        <mat-form-field class="example-form-field" *ngIf="motivo=='3'" appearance="outline">
          <mat-label>Escribe tu motivo de rechazo</mat-label>
          <input matInput type="text" [(ngModel)]="otroMotivo">
        </mat-form-field>
      </div>


    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button class="volver" mat-button mat-dialog-close [routerLink]="['/pre-oferta']">Volver a ofertar</button>
      <button class="finalizar" (click)="rechazarOferta()">Rechazar oferta</button>
    </mat-dialog-actions>
  </div>
</div>
