import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CustomValidators } from 'src/app/proveiders/CustomValidators';
import { RcActualizaPassService } from '../../../../services/recuperacion/rc-actualiza-pass.service';

// Utils
import { FormChecks } from 'src/app/v2/class/FormChecks/form-checks';
import { DialogService } from 'src/app/services/dialog.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {

  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'expired-password',
  templateUrl: './expired-password.component.html',
  styleUrls: ['./expired-password.component.css']
})
export class ExpiredPasswordComponent implements OnInit {

  public recuperacionForm: FormGroup;
  public isValidConfirmar: boolean = false;
  public showActual: boolean = false;
  public showPassword: boolean = false;
  public showConfirmar: boolean = false;
  private debounceTime?: NodeJS.Timeout;
  public showError: boolean = false;
  public showV = false;
  public passwordForm: FormGroup;

  usuario: any;
  otpForm: FormGroup;
  hide: boolean = true;
  errorLog: String = '';
  matcher = new MyErrorStateMatcher();
  code = ""
  numuser = ""
  constructor(
    private router: Router,
    private fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    private apiPass: RcActualizaPassService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    // console.log('***',localStorage.getItem('user'))

    this.iniciarFormulario();

    if (parseInt(localStorage.getItem('change-password')) === 1) {
      this.dialogService.openDialog("Contraseña enviada","En tu correo recibirás la información con la <strong>solicitud de cambio de contraseña</strong>", true)
    }

    this.passwordForm = this.fb.group({
      'old-password': [null, [
        Validators.required,
      ]],
      'new-password': [{
        value: null,
        disabled: true
      }, [
        Validators.required,
        Validators.minLength(8),
        FormChecks.checkNumber,
        FormChecks.checkSpecialCharacter,
        FormChecks.checkMayus,
        FormChecks.checkMinus
      ]],
      'confirm-password': [{
        value: null,
        disabled: true
      }, [
        Validators.required,
      ]]
    }, {
      validators: FormChecks.checkIfEqual
    });

    this.passwordForm.get('old-password').valueChanges.subscribe((value) => {
      if (value !== null) {
        this.passwordForm.get('new-password').enable();
        this.passwordForm.get('confirm-password').enable();
      } else {
        this.passwordForm.get('new-password').disable();
        this.passwordForm.get('confirm-password').disable();
      }
    })
  }


  // Método para obtener el control del formulario
  get passwordold() {
    return this.passwordForm.get('old-password');
  }

  // Método para obtener el control del formulario
  get passwordnew() {
    return this.passwordForm.get('new-password');
  }

  // Método para obtener el control del formulario
  get passwordconfirm() {
    return this.passwordForm.get('confirm-password');
  }

  iniciarFormulario() {
    this.recuperacionForm = new FormGroup({
      actual: new FormControl('', Validators.required),
      nueva: new FormControl('', Validators.compose([
        Validators.required,
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true,
        }),
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        }),
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        }),
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true,
          }
        ),
        Validators.minLength(8),
      ])),
      confirmar: new FormControl('', Validators.compose([
        Validators.required,
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true,
        }),
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        }),
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        }),
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true,
          }
        ),
        Validators.minLength(8),
      ])),
    })
  }
  changeComfirmar(query) {
    const { nueva } = this.recuperacionForm.value;
    if (this.debounceTime) clearTimeout(this.debounceTime);
    this.debounceTime = setTimeout(() => {
      if (nueva == query) {
        this.isValidConfirmar = true;
      } else {
        this.isValidConfirmar = false;
      }
    }, 500);
  }
  changePassword() {
    const { nueva: clave, actual: claveAct } = this.recuperacionForm.value;
    const user = localStorage.getItem('user')
    this.apiPass
      .validationPassword(
        user,
        claveAct,
        clave,
        2
      )
      .subscribe(
        (data: any) => {
          this.dialogService.openDialog(
            'Contraseña actualizada',
            'Ya puedes iniciar sesión con tu nueva contraseña',
            true,
            'Iniciar sesión',
            () => {
              localStorage.clear();
              this.router.navigate(['']);
            }
          )
        }
      );
  }
  showValid(event: any) {
    if (event == '') {
      this.showV = false;
    } else {
      this.showV = true;
    }
  }


}
