// Angular
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';
import { RcActualizaPassService } from 'src/app/services/recuperacion/rc-actualiza-pass.service';

// Utils
import { FormChecks } from 'src/app/v2/class/FormChecks/form-checks';

@Component({
  selector: 'app-cambio-contrasena',
  templateUrl: './cambio-contrasena.component.html',
  styleUrls: ['./cambio-contrasena.component.css'],
  host: { class: "app-cambio-contrasena" }
})
export class CambioContrasenaComponent {

  public passwordForm: FormGroup;
  public incorrectPassword: boolean = false
  public changedPassword: boolean = false

  hide: boolean = true;
  showError: boolean = false;
  errorLog: string = '';
  numuser = "";
  otp = "";

  constructor(
    private fb: FormBuilder,
    private api: RcActualizaPassService,
    public activatedRoute: ActivatedRoute,
    public dialogService: DialogService
  ) { }

  ngOnInit(): void {
    let state = this.activatedRoute.paramMap.pipe(map(() => window.history.state))

    state.subscribe(x => {
      this.otp = x.otp;
      this.numuser = x.numuser;
    })

    this.passwordForm = this.fb.group({
      'old-password': [null, [
        Validators.required,
      ]],
      'new-password': [{
        value: null,
        disabled: true
      }, [
        Validators.required,
        Validators.minLength(8),
        FormChecks.checkNumber,
        FormChecks.checkSpecialCharacter,
        FormChecks.checkMayus,
        FormChecks.checkMinus
      ]],
      'confirm-password': [{
        value: null,
        disabled: true
      }, [
        Validators.required,
      ]]
    }, {
      validators: FormChecks.checkIfEqual
    });

    this.passwordForm.get('old-password').valueChanges.subscribe((value) => {
      if (value !== null) {
        this.passwordForm.get('new-password').enable();
        this.passwordForm.get('confirm-password').enable();
      } else {
        this.passwordForm.get('new-password').disable();
        this.passwordForm.get('confirm-password').disable();
      }
    })
  }

  // Método para obtener el control del formulario
  get passwordold() {
    return this.passwordForm.get('old-password');
  }

  // Método para obtener el control del formulario
  get passwordnew() {
    return this.passwordForm.get('new-password');
  }

  // Método para obtener el control del formulario
  get passwordconfirm() {
    return this.passwordForm.get('confirm-password');
  }

  protected actRc() {
    let responses: any;

    this.api
      .actualizarPassword(
        'ConectaFrontEnd',
        this.numuser,
        this.passwordnew.value,
        this.otp
      )
      .subscribe(
        (data: any) => {
          console.log(data);
          this.errorLog = data.message;
          this.dialogService.openDialog("!", `${this.errorLog}`, false)
          responses = data;
          if (responses.code == 200) {
            this.changedPassword = true
          }
          else {
            this.errorLog = responses.message;
          }
        },
        (error) => {
          this.dialogService.openDialog("!",`Error actualizando contraseña`,false)
          this.errorLog = error.error.message;
        }
      );
  }


}
