import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-look-feel',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent {
  @Input() size: number = 64; // Tamaño por defecto (64px)
}
