<div class="container mt-5">
  <div class="stepper-container mb-0 mb-md-5" [ngClass]="{'disabled': disabled}">
    <!-- Stepper Progress Bar -->
    <div class="progress" style="height: 3px;">
      <div class="progress-bar" role="progressbar" [style.width.%]="progress" aria-valuenow="progress" aria-valuemin="0"
        aria-valuemax="100"></div>
    </div>

    <!-- Step Indicators -->
    <div *ngFor="let step of steps; let i = index" class="step-indicator position-absolute text-center"
      [style.left.%]="getStepPosition(i)" (click)="goToStep(i)" [ngClass]="{
        'completed': i <= maxStepReached && i !== currentStep,
        'not-allowed': !sucessStep,
        'active': i === currentStep
      }">
      <div class="step-circle">
        <img *ngIf="i == steps.length - 1 && sucessStep; else elseBlock"
          src="./../../../assets/icons/chulito-stepper.svg" alt="" />
        <ng-template #elseBlock>
          <div>
            {{ i + 1 }}
          </div>
        </ng-template>
      </div>
      <div class="step-title mt-2 d-none d-md-block lh-1" [innerHTML]="step"></div>
    </div>
  </div>

  <!-- Step Content -->
  <div class="step-content py-2">
    <ng-container *ngFor="let content of stepContents; let i = index">
      <ng-container *ngIf="i === currentStep">
        <ng-template [ngTemplateOutlet]="content"></ng-template>
      </ng-container>
    </ng-container>
  </div>

  <!-- Navigation Buttons -->
  <div class="step-footer d-flex justify-content-between align-items-center">
    <app-hyperlink-icon iconPosition="start" icon="arrow" [ngClass]="buttonBackVisibility ? '' : 'invisible'"
      (clicked)="previousStep()">Volver</app-hyperlink-icon>
    <app-hyperlink-icon iconPosition="end" icon="arrow" [disabled]="!completeStep" [ngClass]="buttonNextVisibility ? '' : 'invisible'"
      (clicked)="nextStep()">Guardar y continuar</app-hyperlink-icon>
  </div>
</div>
