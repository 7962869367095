<div class="d-flex flex-column align-items-center">
  <p class="mb-0 text-center" *ngFor="let rule of rules">
    <img src="assets/icons/check-blue.svg" alt="" />
    <span [innerHTML]="rule"></span>
  </p>
  <div class="mt-6">
    <app-multiple-file-upload-card-dotted [title]="title" [subtitle]="subtitle" [documents]="masAdicionals"
      [secuencia]="secuencia" [contract]="contract"
      [contrato_url]="data.contrato_url"></app-multiple-file-upload-card-dotted>
  </div>
  <div class="d-flex justify-content-between align-items-center my-5 w-100 footer-buttons">
    <app-hyperlink-icon iconPosition="start" icon="arrow">Volver</app-hyperlink-icon>
    <button-look-feel size="small" color="secondary" [disabled]="masAdicionals.length==0" [loading]="loading"
      (clicked)="subirArchivos()">Enviar</button-look-feel>
  </div>
