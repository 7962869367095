<div class="modal-dialog-centered">
  <div class="modal-content p-4">
    <h4 class="me-auto">{{titulo}}</h4>
    <p class="mt-1" [innerHTML]="mensaje"></p>
    <div class="d-flex justify-content-evenly border-0">
      <button-look-feel *ngIf="backButton" size="small" color="outline"  (clicked)="handleBackButton()">{{textBackButton}}</button-look-feel>
      <button-look-feel [size]="backButton?'small':'medium'" color="secondary" (clicked)="handleNextButton()">{{textNextButton}}</button-look-feel>
    </div>
  </div>
</div>
