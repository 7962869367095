import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { GetInformacionService } from 'src/app/services/perfilamiento/get-informacion.service';
import { environment } from 'src/environments/environment';
import { DataService } from '../../../services/data.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-listado-celulares',
  templateUrl: './listado-celulares.component.html',
  styleUrls: ['./listado-celulares.component.scss'],
})
export class ListadoCelularesComponent implements OnInit {
  seccion = 'datos';
  formularioInformacion: FormGroup;
  celulares: Array<any> = [];
  celular: string;
  loading: boolean = false;
  telefono = '';
  haveCellphones: boolean = true;
  intentosContinuar = 0;

  constructor(
    private data: DataService,
    private getinfoServ: GetInformacionService,
    private router: Router,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  ngAfterViewInit(): void {
    const article = document.querySelector('article');
    if (article) {
      article.classList.remove('bg-blue-gradient-bottom');
    }

    this.celulares = this.data.getListadoCelulares();

    this.celulares.push({
      celular: 'Ninguno de los anteriores',
    });
  }

  ngOnDestroy() {
    const article = document.querySelector('article');
    if (article) {
      article.classList.add('bg-blue-gradient-bottom');
    }
  }

  async savenumber(): Promise<void> {
    this.loading = true;
    try {
      console.log({ intentos: this.intentosContinuar });
      if (this.intentosContinuar === 1) {
        return;
      }
      this.intentosContinuar = 1;
      console.log({ intentos: this.intentosContinuar });

      const isVoid = this.telefono.includes('Ninguno');
      let value = isVoid ? 0 : 1;

      let celular = this.telefono;
      if (!environment.production) {
        celular = this.data.cellphone;
      }
      this.data.cellphone = celular;

      this.data.isNewPhone = isVoid;

      this.getinfoServ
        .saveCascadaPhone({
          ind: value,
          celular,
          carpeta: this.data.numeroCarpeta.toString(),
          cedula: this.data.cedula.toString(),
        })
        .subscribe(
          async (resp) => {
            this.router.navigateByUrl('/' + resp.data.ruta);
          },
          (error) => {
            this.loading = false;
            this.intentosContinuar = 0;
            this.modalAuto(error.error.message);
          }
        );
    } catch (error) {
      this.intentosContinuar = 0;
      this.loading = false;
      this.modalAuto(error.error.message);
    }
  }

  modalAuto(message = '') {
    this.dialogService.openDialog('Celular incorrecto', message, false);
  }
}
