<div class="contenido">
  <h1>Validación de identidad</h1>
  <h2>Datos del solicitante del crédito</h2>
  <div class="row">
    <p>
      Para la validación de identidad del solicitante, como corredor/asesor y en calidad del responsable de la información, deberás seleccionar el correo electrónico que confirma el solicitante:
    </p>
  </div>
  <br>
  <div class="row">
    <mat-radio-group [(ngModel)]="mail" aria-labelledby="example-radio-group-label" class="telefono-group">
      <mat-radio-button class="telefono-button" *ngFor="let correo of correos" [value]="correo.email">
        {{ correo.email }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <button *ngIf="havemails" class="continuar btn-dc" [disabled]="mail == '' || loading" mat-button
    (click)="savemail()">
    <mat-icon *ngIf="loading">
      <mat-spinner diameter="25"></mat-spinner>
    </mat-icon>
    Continuar
  </button>
</div>
