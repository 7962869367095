<div class="tarjeta rounded-2 d-flex mt-2 p-3" [ngClass]="[customClass, loading ? 'loading' : '']">
  <img src="./../../../assets/icons/archivo-cargado.svg" style="height: 42px; margin: auto 0;" class="me-3" />
  <div class="w-100 d-flex align-items-center" *ngIf="!loading">
      <div class="name pe-1 text-wrap flex-fill" style="max-width: 13rem;">{{fileName}}</div>
      <span class="size ps-1 fw-normal">{{fileSize | fileSize}}</span>
      <app-hyperlink-icon iconPosition="start" icon="delete" (clicked)="eliminar()">Eliminar</app-hyperlink-icon>
  </div>
  <ng-container *ngIf="loading">
    <div class="w-100">
      <div class="fw-semibold">
        Cargando
      </div>
      <app-progress></app-progress>
    </div>
  </ng-container>
</div>
