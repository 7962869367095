<main class="bg-login w-screen h-screen relative overflow-auto min-h-middle">
  <figure class="icon-clic hidden md:block">
    <img src="../../../assets/img/logo.png" alt="logo bayport" class="w-3rem" />
  </figure>
  <article class="w-full h-full absolute top-0 left-0 grid">
    <div class="my-auto w-full h-min">
      <header class="relative text-center mx-auto mb-4">
        <figure class="md:hidden icon-clic-2">
          <img
            src="../../../assets/img/logo-2.svg"
            alt="logo bayport"
            align="center"
            class="w-4rem mb-2"
            style="width: 38px; height: 24px; top: 106px"
          />
        </figure>
        <h1 class=" text-login mb-3" align="center" style="top: 167px;">
          Bienvenido a tu <br class="hidden md:block"> oficina virtual
        </h1>
        <h5 class="fw-semibold text-sm md:text-base xltext-lg text-sky" align="center">
          Gestiona las solicitudes de crédito <br class="block md:hidden"> de tus clientes
        </h5>
      </header>
      <div class="m-auto div-form">
        <section class="mt-1 flex justify-content-center">
          <article
            class="bg-blue-gradient w-full px-4 sm:py-6 sm:border-1 border-round border-sky-200"
          >
            <form [formGroup]="loginForm" (ngSubmit)="onLogin()" class="mt-0">
              <div class="flex flex-column gap-2 mb-2">
                <strong class=" font-bold text-xl text-white mb-3">Iniciar sesión</strong>
                <label htmlFor="user" class="text-white m-0">
                  <strong class=" text-base">
                    Usuario
                  </strong>
                </label>
                <input pInputText formControlName="user" placeholder="*número de cédula" class="p-inputtext-sm "
                  maxlength="15" />
                <small *ngIf="userValue?.hasError('required') && userValue.touched" class="validation-message">
                  El usuario es requerido
                </small>
                <div *ngIf="userValue.value !== ''">
                  <small *ngIf="loginForm.controls.user.hasError('pattern')" class=" validation-message">
                    Este campo solo acepta <strong>números</strong>
                  </small>
                  <small *ngIf="loginForm.controls.user.hasError('minlength')" class="validation-message">
                    Minimo 8 caracteres
                  </small>
                </div>
              </div>
              <div class="flex flex-column gap-2">
                <label htmlFor="password" class="text-white m-0">
                  <strong class=" text-base">
                    Contraseña
                  </strong>
                </label>
                <p-password
                  formControlName="password"
                  placeholder="*contraseña"
                  [feedback]="false"
                  [toggleMask]="true"
                  maxlength="100"
                  class="p-inputtext-sm"
                  inputStyleClass="w-full"
                  styleClass="w-full"
                ></p-password>
                <small
                  *ngIf="passwordValue?.errors?.['required'] && (passwordValue?.touched || passwordValue?.dirty)"
                  class="validation-message"
                >
                  La contraseña es requerida
                </small>
                <div *ngIf="passwordValue.value !== ''">
                  <small *ngIf="loginForm.controls.password.hasError('pattern')" class="validation-message">
                    La contraseña no coincide con el patrón
                  </small>
                  <small *ngIf="loginForm.controls.password.hasError('minlength')" class="validation-message">
                    Minimo 8 caracteres
                  </small>
                  <small *ngIf="loginForm.controls.password.hasError('maxlength')" class="validation-message">
                    Maximo 33 caracteres
                  </small>
                  <small class="validation-message text-pink" *ngIf="errorLog">
                    {{ errorLog }}
                  </small>
                </div>
              </div>
              <br />
              <div class="px-6 flex justify-content-center">
                <button pButton label="Iniciar sesión" type="submit" [disabled]="!this.loginForm.valid"
                  class=" w-full text-base px-6 py-2 mt-4 text-gray-900 bg-sky-200 border-none"></button>
              </div>
            </form>
            <footer class="text-center mt-4" style="padding-top: 0%">
              <a
                routerLink="/recuperar-contrasena"
                class="text-sky-200 hover:text-underline"
              >
                ¿Olvidaste tu contraseña?
              </a>
            </footer>
          </article>
        </section>
      </div>
    </div>
  </article>
</main>
