import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SafeUrlPipe } from '../../pipes/safe-url.pipe';
import { DownloadCertIngresoRetComponent } from './components/download-cert-ingreso-ret/download-cert-ingreso-ret.component';
import { IdleModalComponent } from './components/idle-modal/idle-modal.component';
import { LoadDocsCardComponent } from './components/load-docs-card/load-docs-card.component';
import { PdfViewDialogComponent } from './components/pdf-view-dialog/pdf-view-dialog.component';

// PIPES
import { CapitalizePipe } from 'src/app/pipes/capitalize.pipe';
import { CelularPipe } from 'src/app/pipes/celular.pipe';
import { PaginatorPipe } from 'src/app/pipes/paginator.pipe';
import { SeguroVoluntarioPipe } from 'src/app/pipes/seguro-voluntario.pipe';
import { SucursalPipe } from 'src/app/pipes/sucursal.pipe';
import { FileSizePipe } from 'src/app/pipes/FileSize.pipe';

@NgModule({
  declarations: [
    LoadDocsCardComponent,
    IdleModalComponent,
    SafeUrlPipe,
    DownloadCertIngresoRetComponent,
    PdfViewDialogComponent,
    FileSizePipe,
    SeguroVoluntarioPipe,
    SucursalPipe,
    PaginatorPipe,
    CelularPipe,
    CapitalizePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatBadgeModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    PdfViewerModule,
  ],
  exports: [
    LoadDocsCardComponent,
    IdleModalComponent,
    DownloadCertIngresoRetComponent,
    PdfViewDialogComponent,
    SafeUrlPipe,
    FileSizePipe,
    SeguroVoluntarioPipe,
    SucursalPipe,
    PaginatorPipe,
    CelularPipe,
    CapitalizePipe
  ],
})
export class SharedModule {}
