import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/proveiders/CustomValidators';
import { RcSolicitudService } from 'src/app/services/recuperacion/rc-solicitud.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-cambia-password',
  templateUrl: './cambia-password.component.html',
  styleUrls: ['./cambia-password.component.css']
})
export class CambiaPasswordComponent implements OnInit {

  public recuperacionForm: FormGroup;
  public isValidConfirmar: boolean = false;
  public showActual: boolean = false;
  public showPassword: boolean = false;
  public showConfirmar: boolean = false;
  private debounceTime?: NodeJS.Timeout;
  public showError: boolean = false;
  public showV = false;

  constructor(
    private _serivice: RcSolicitudService,
    private router: Router,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.iniciarFormulario();

    if (parseInt(localStorage.getItem('change-password')) === 1) {
      this.dialogService.openDialog("Contraseña enviada","En tu correo recibirás la información con la <strong>solicitud de cambio de contraseña</strong>", true)
    }
  }

  iniciarFormulario() {
    this.recuperacionForm = new FormGroup({
      actual: new FormControl('', Validators.required),
      nueva: new FormControl('', Validators.compose([
        Validators.required,
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true,
        }),
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        }),
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        }),
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true,
          }
        ),
        Validators.minLength(8),
      ])),
      confirmar: new FormControl('', Validators.compose([
        Validators.required,
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true,
        }),
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        }),
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        }),
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true,
          }
        ),
        Validators.minLength(8),
      ])),
    })
  }

  changeComfirmar(query) {
    const { nueva } = this.recuperacionForm.value;
    if (this.debounceTime) clearTimeout(this.debounceTime);
    this.debounceTime = setTimeout(() => {
      if (nueva == query) {
        this.isValidConfirmar = true;
      } else {
        this.isValidConfirmar = false;
      }
    }, 500);
  }

  changePassword() {
    const { nueva: clave } = this.recuperacionForm.value;
    this._serivice.updatePassword(clave).subscribe(resp => {
      console.log('MENSAJE CAMBIO', resp);
      this.dialogService.openDialog(
        '¡Creaste una nueva contraseña!',
        "",
        true,
        'Ir al inicio',
        () => {
          this.router.navigate(['/home']);
        }
      )
    }, error => {
      this.dialogService.openDialog(
        'Opps !!',
        `${error.error.message}`,
        false
      )
    });
  }

  showValid(event: any) {
    if (event == '') {
      this.showV = false;
    } else {
      this.showV = true;
    }
  }

}
