<!--app-header></app-header-->

<div class="contenido">
  <h1>Selección de Pre-oferta</h1>
  <br />
  <p>
    Antes de continuar conoce el desglose de los conceptos incluidos dentro de
    la pre-oferta.
  </p>

  <table class="table table-striped">
    <tbody>
      <tr>
        <td>
          <strong>Seguro de vida grupo deudor¹</strong>
        </td>
        <td
          colspan="2"
          style="border-left: none !important; align-content: right"
          align="right"
        >
          {{ ofertas?.tasaSeguro }}% &nbsp;&nbsp;&nbsp;&nbsp;
        </td>
      </tr>
      <tr>
        <td>
          <strong>Garantía² </strong>(Amparo Adicional de Protección Económica Complementaria del Tomador)
        </td>
        <td colspan="2" style="border-left: none !important" align="right">
          {{ ofertas?.tasaAval }}%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </td>
      </tr>
      <tr>
        <td>
          <strong>Estudio y administración del crédito³</strong>
        </td>
        <td colspan="2" style="border-left: none !important" align="right">
          {{ ofertas?.estudioCredito }}%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </td>
      </tr>
      <tr>
        <td>
          <strong>Comisión de corretaje</strong>
        </td>
        <td colspan="2" style="border-left: none !important" align="right">
          {{ ofertas?.porcentajeCorretaje }}%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </td>
      </tr>
      <tr>
        <td>
          <strong>Tasa interés corriente⁴ </strong>(N.M.V.: Nominal Mes Vencido)
        </td>

        <td align="right">
          <div *ngIf="tasas.length > 1">
            <div
              id="botones"
              *ngFor="let tasa of tasas; let i = index"
              [class]="tasa.tasa == tasaSeleccionada ? 'active' : ''"
            >
              <button
                type="radio"
                name="btnradio"
                class="flexi btn btn-check"
                [ngClass]="{ normal: i === 1 }"
                (click)="cambiarTasa(tasa.tasa, 'flexi' + i)"
                [(ngModel)]="tasaSeleccionada"
                [value]="tasa.tasa"
                type="radio"
                name="tipoIdentificacion"
                autocomplete="off"
                [id]="'flexi' + i"
              >
                {{ i === 0 ? "Flexi" : "Normal" }} {{ tasa.tasa }}%
              </button>
            </div>
          </div>
          <div *ngIf="tasas.length === 1">
            <div *ngFor="let tasa of tasas">
              Normal {{ tasa.tasa }}%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <p>
    A continuación encontrarás las ofertas según la información proporcionada
    por el solicitante.
  </p>
  <div class="box-container">
    <div class="box-item">
      <mat-card
        style="box-shadow: 0px 2px 4px -1px rgba(86, 101, 115, 1)"
        class="example-card"
      >
        <div style="height: 16px"></div>
        <div class="header-card">
          <h4 style="color: #ffffff !important">Crédito solicitado</h4>
        </div>

        <div class="no-upload" #ref1 id="upload-1">
          <tr>
            <td>Capacidad</td>
            <td>Monto</td>
          </tr>
          <tr>
            <th>${{ ofertas?.capacidad | number : "1.0-0" }}</th>
            <th>${{ ofertas?.monto | number : "1.0-0" }}</th>
          </tr>

          <tr>
            <td>Plazo</td>
            <td>Cuota</td>
          </tr>
          <tr>
            <th>{{ ofertas?.plazo }} meses</th>
            <th>$ {{ ofertas?.cuota | number : "1.0-0" }}</th>
          </tr>

          <tr>
            <td>Comisión/millón</td>
          </tr>
          <tr>
            <th>${{ ofertas?.comisionMillon | number : "1.0-0" }}</th>
          </tr>

          <div class="radio-group">
            <div class="position">
              <input
                type="radio"
                id="radio1"
                name="flexRadioDefault"
                value="1"
                (click)="credito = '1'"
              />
              <label [htmlFor]="'radio1'">
                {{ credito == "1" ? "Seleccionada" : "Seleccionar" }}
              </label>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

    <div class="box-item">
      <mat-card
        style="box-shadow: 0px 2px 4px -1px rgba(86, 101, 115, 1)"
        class="example-card"
      >
        <div style="height: 16px"></div>
        <div class="header-card-max">
          <h4>Crédito máximo</h4>
        </div>
        <div class="no-upload" #ref1 id="upload-1">
          <tr>
            <td>Capacidad</td>
            <td>Monto</td>
          </tr>
          <tr>
            <th>${{ ofertas?.capacidad | number : "1.0-0" }}</th>
            <th>${{ ofertas?.montoMaximo | number : "1.0-0" }}</th>
          </tr>

          <tr>
            <td>Plazo</td>
            <td>Cuota</td>
          </tr>
          <tr>
            <th>{{ ofertas?.plazoMaximo }} meses</th>
            <th>${{ ofertas?.cuotaMaxima | number : "1.0-0" }}</th>
          </tr>

          <tr>
            <td>Comisión/millón</td>
          </tr>
          <tr>
            <th>${{ ofertas?.comisionMillon | number : "1.0-0" }}</th>
          </tr>

          <div class="radio-group">
            <div class="position">
              <input
                type="radio"
                id="radio2"
                name="flexRadioDefault"
                value="2"
                (click)="credito = '2'"
                checked
              />
              <label [htmlFor]="'radio2'">
                {{ credito == "2" ? "Seleccionada" : "Seleccionar" }}</label
              >
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="cg">
    <div class="t-cg">
      <strong> Seguro voluntario accidentes personales </strong>
    </div>

    <div class="c-cg">
      <strong>Comisión:</strong> ${{
        continuarSinSeguro || !seguroVoluntario
          ? "0"
          : (seguroVoluntario.valorComision | number : "1.0-0")
      }}
    </div>
    <div class="c-cg">
      <strong>Prima diaria:</strong> ${{
        continuarSinSeguro || !seguroVoluntario
          ? "0"
          : (seguroVoluntario.primaDiaria | number : "1.0-0")
      }}
    </div>
    <div class="c-cg">
      <strong>Valor asegurado:</strong> ${{
        continuarSinSeguro || !seguroVoluntario
          ? "0"
          : (seguroVoluntario.valorCobertura | number : "1.0-0")
      }}
    </div>

    <div class="c-cg">
      <a
        role="button"
        (click)="irSeguroVoluntario()"
        style="color: var(--p-blue); text-decoration: underline var(--p-blue)"
        >¿Deseas modificar el plan de seguro?</a
      >
    </div>
  </div>

  <div>
    <div class="t-end">
      <div class="botones-rag">
        <a
          class="link-rec"
          role="button"
          (click)="rechazaOferta()"
          style="cursor: pointer"
          >Rechazar oferta</a
        >
        <div>
          <button class="btn-cam-wht" (click)="ajustarCondiciones()" mat-button>
            Ajustar condiciones
          </button>
        </div>
      </div>
      <div>
        <button (click)="aceptarOferta()" class="btn-cam-rag" mat-button>
          <mat-icon *ngIf="isLoaded" style="margin-right: 5px">
            <mat-spinner diameter="25"></mat-spinner>
          </mat-icon>
          Guardar y continuar
        </button>

        <button (click)="aceptarOferta()" class="m-bot" mat-button>
          <mat-icon *ngIf="isLoaded" style="margin-right: 5px">
            <mat-spinner diameter="25"></mat-spinner>
          </mat-icon>
          Guardar y continuar
        </button>
      </div>
    </div>
  </div>

  <p>
    <mat-checkbox
      class="example-margin"
      [(ngModel)]="continuarSinSeguro"
      (change)="continuarSinSeguroFunc()"
      [disabled]="disabledCheck"
    >
      Continuar sin plan de seguro voluntario
    </mat-checkbox>
  </p>
  <div id="accordion">
    <div class="card">
      <div class="card-header" id="headingOne">
        <a
          class="politicasColl"
          (click)="collapse()"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          Conoce en qué consisten los conceptos incluidos dentro de la
          pre-oferta
          <img
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            *ngIf="collapsePoli == 'false'"
            src="../../../assets/icons/PolígonoArriba.svg"
          />
          <img
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            *ngIf="collapsePoli == 'true'"
            src="../../../assets/icons/PolígonoAbajo.svg"
          />
        </a>
      </div>

      <div
        id="collapseOne"
        class="collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#accordion"
      >
        <div class="card card-body">
          <div class="subTitulo">
            <b>¹ Seguro de vida grupo deudor: </b>El valor de la prima del
            seguro de vida grupo deudor, es un valor dinámico que varía con el
            pago del crédito. Para conocer el valor total en números absolutos
            de este concepto, podrá consultar el documento de condiciones
            particulares del seguro de vida grupo deudor disponibles en su
            paquete de crédito.
            <br />
            El seguro de vida grupo deudor cubre el saldo insoluto de la deuda
            (valor capital) que no ha sido pagado, en los siguientes eventos:<br />
            * Fallecimiento por cualquier causa <br />
            * Incapacidad total y permanente <br />
            * Enfermedades graves<br />
            El seguro cubre el riesgo de pérdida involuntaria del empleo del
            asegurado hasta por seis (6) meses, como consecuencia del despido
            por parte del empleador, ocurrido por alguna de las siguientes
            causas: Despido sin justa causa, declaratoria de insubsistencia,
            despido de empleados de libre nombramiento y remoción, cierre
            temporal o definitivo de la empresa, suspensión cargos por fusión,
            transformación o liquidación de en entidades públicas. Lo anterior
            siempre y cuando a la fecha de ocurrencia del desempleo tenga como
            mínimo seis(6) meses continuos laborando con el mismo patrono, y con
            contrato laboral escrito a término indefinido o nombramiento por
            acto administrativo.
          </div>
          <br />
          <div class="subTitulo">
            <b>² Garantía: (Amparo Adicional de Protección Económica Complementaria del Tomador):</b>
            Se trata de un mecanismo para cubrir el riesgo de incumplimiento del crédito.
            Está garantía es otorgada por un tercero sugerido por la Compañía y, en caso de que dicho tercero sea aceptado por el Cliente,
            el valor del amparo adicional se descontará directamente del valor total del crédito. De esta manera,
            le es descontado el porcentaje del amparo adicional correspondiente a las condiciones del crédito al momento del desembolso de este.
            No obstante, esta opción de garantía es sugerida y en consecuencia el Cliente tendrá la plena potestad de contratar el amparo de protección
            económica de manera independiente o conjunta, ya sea con la opción presentada por la Compañía o con la aseguradora que el cliente libremente defina,
            siempre y cuando las coberturas, exclusiones y vigencia de dicha póliza correspondan a las mismas presentadas por la aseguradora sugerida por la Compañía.
            *Esta “garantía” consistente en un amparo patrimonial dentro de un seguro de vida deudor*
          </div>
          <br />
          <div class="subTitulo">
            <b>³ Estudio de administración del crédito: </b>
            Para la presente operación crediticia el CLIENTE manifiesta que entiende y acepta que pagará una tarifa por Estudio y Administración del crédito.
            el cual será causado y/o pagadero, a elección del CLIENTE, de forma diferida a través de las cuotas del crédito, o al desembolso del valor del crédito,
            pero en todo caso no más allá de la fecha de terminación efectiva de este por cualquier causa. En casos de refinanciación, cuando el CLIENTE haya diferido
            el pago del estudio de administración de crédito y exista un monto pendiente de pago al momento de refinanciar el crédito, el monto pendiente será sumado al
            rubro correspondiente al nuevo estudio de administración de crédito sin que en ningún caso se superen los límites legales aplicables para el cobro de intereses.
          </div>
          <br />
          <div class="subTitulo">
            <b>⁴ Tasa de interés corriente (N.M.V: Nominal Mes Vencido): </b>Es
            Es la tasa de interés remuneratorio pagado por el Cliente sobre los saldos insolutos del capital, bajo la modalidad de tasa fija,
            dentro de los límites legales aplicables.
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="footer">
    Todo el contenido del Portal Agentes es propiedad exclusiva y reservada del
    Bayport Colombia S.A., por lo tanto la información que se presenta, es
    información NO dirigida directamente al Solicitante y/o Cliente ni se puede
    considerar como oferta para el mismo. No se autoriza su reproducción,
    utilización ni explotación a ningún tercero.
  </p>
</div>
<script type="text/javascript">
  user_id = localStorage.getItem("usuarioId");

  if (typeof QSI === "undefined") {
    QSI = {};
    QSI.config = {
      externalReference: user_id,
    };
  }
</script>
