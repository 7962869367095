<div class="d-flex flex-column align-items-center my-0 my-md-5">
  <p class="d-none d-md-block mb-0 text-center" *ngFor="let rule of rules">
    <img src="assets/icons/check-blue.svg" alt="" />
    <span [innerHTML]="rule"></span>
  </p>
  <div class="d-flex flex-wrap justify-content-center my-3">
    <div class="m-3" *ngFor="let file of files">
      <app-card-file-upload
      [iconoCentral]="file.iconoCentral"
      [titulo]="file.titulo"
      [texto]="file.texto"
      [iconoLoading]="file.iconoLoading"
      [iconoLoaded]="file.iconoLoaded"
      [file]="file.file"
      [rules]="rules"
      ></app-card-file-upload>
    </div>
  </div>
</div>
