import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CryptoService } from 'src/app/services/crypto.service';
import { DialogService } from 'src/app/services/dialog.service';
import { DataOfertaService } from 'src/app/services/genOferta/dataOferta.service';

@Component({
  selector: 'app-autorizacion-transferencia',
  templateUrl: './autorizacion-transferencia.component.html',
  styleUrls: ['./autorizacion-transferencia.component.css'],
})
export class AutorizacionTransferenciaComponent implements OnInit {
  constructor(
    private crip: CryptoService,
    private _dataOfertaService: DataOfertaService,
    private dialogServ: DialogService
  ) {
    this._dataOfertaService.cleanData();
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  ngAfterViewInit(): void {
    const article = document.querySelector('article');
    if (article) {
      article.classList.remove('bg-blue-gradient-bottom');
    }

    let y = {
      cedula: 80038506,
      cellphone: '3203202355',
      code: '123456',
      idTransaccionOTP: '77e2342c-c6bf-4530-8e4f-bdb3d8da6598',
      regValidacion: '6153358',
    };
    let x = this.crip.encriptar(y);

    console.log('http://localhost:4200/politicas?data=' + x);
  }

  modalAuto() {
    this.dialogServ.openDialog("Advertencia", "La información del solicitante que registres a continuación será utilizada durante la validación de identidad y todo el proceso de solicitud.", true)
  }
}
