<app-card-dotted-line>
  <div class="d-md-flex justify-content-between">
    <div class="mx-2 mt-2">
      <h5 class="me-md-4" [innerHTML]="title"></h5>
      <p class="mb-1 me-md-4" [innerHTML]="subtitle"></p>
    </div>
    <div class="d-flex flex-column align-items-center">
      <button-look-feel *ngIf="contract" size="small" color="secondary" (clicked)="getContrato()" [loading]="contrato_url==null">
        descargar <img src="./../../../assets/icons/descargar-btn-blue.svg"
          style="width: 12px; margin-top: -5px; margin-left: 6px;" /></button-look-feel>
      <button-look-feel size="small" color="outline" (clicked)="clicFileUpload()"
        [disabled]="contrato_url==null && contract">
        cargar <img src="./../../../assets/icons/cargar-btn-cyan.svg"
          style="width: 12px; margin-top: -5px; margin-left: 6px;" /></button-look-feel>
      <input #fileInput multiple type="file" accept=".png, .jpg, .pdf" (change)="onFileSelected($event)"
      class="d-none" />
    </div>
  </div>
  <div class="contenedor-archivos  mx-2 my-2 border-top" *ngIf="documents.length>0">
    <app-load-file-card *ngFor="let doc of documents; let i = index" [fileName]="doc.nombre"
      [fileSize]="doc.archivo.size" [loading]="true" (eliminarArchivo)="deleteDymamic(i)"></app-load-file-card>
  </div>
</app-card-dotted-line>
