import { HttpClient } from '@angular/common/http';
import { Component, inject, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PdfViewDialogComponent } from '@modules/shared/components/pdf-view-dialog/pdf-view-dialog.component';
import { map } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';
import { BuscCarpetaService } from 'src/app/services/perfilamiento/busc-carpeta.service';
import { GetInformacionService } from 'src/app/services/perfilamiento/get-informacion.service';
import { MotorService } from 'src/app/services/perfilamiento/motor.service';
import { PerfilamientoService } from 'src/app/services/perfilamiento/perf-inf-personal.service';
import { ReconocerService } from 'src/app/services/preOferta/reconocer.service';
import { environment } from 'src/environments/environment';
import { getFilesByFolder, itemFile } from '../../models/busCarpeta.interface';
import { ConvenioService } from '../../services/catalogos/convenios.service';
import { Pagaduriaservice } from '../../services/catalogos/pagadurias.service';
import { DataService } from '../../services/data.service';
import { GenOfertaService } from '../../services/genOferta/gen-oferta.service';
import { ResumenAdoService } from '../../services/resumen-ado.service';

import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-busqueda-carpetas',
  templateUrl: './busqueda-carpetas.component.html',
  styleUrls: ['./busqueda-carpetas.component.scss'],
})
export class BusquedaCarpetasComponent {
  panelOpenState = false;
  placement = 'bottom';
  private calendar = inject(NgbCalendar);

  model: NgbDateStruct;

  isDisabled = (date: NgbDate, current: { month: number; year: number }) =>
    date.month !== current.month;
  isWeekend = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;

  pageNumber: number = 1;
  page_size: number = 3;
  pageSizeOptions = [3, 5, 10];
  isLoading = false;
  searching: boolean = false;
  estado: string = 'busqueda';
  tipoBusqueda: string = 'cedula';
  entradabusqueda: string = '';
  fechaInicial: any = '';
  fechaFinal: any = '';
  convenio: string = '';
  pagaduria: string = '';
  resultadosBusqueda: any[] = [];
  resultadoDetalle: any;
  page_number: number = 0;
  total_pages: number = 0;
  arrayTotal: any[] = [];
  activePage: boolean = false;
  contPages: number = 1;
  resAdo: any[] = [];
  genOf: any;
  convenios: any;
  tiposContratos: any;
  edoLaboral: any;
  cargos: any;
  pagaduarias: any;

  public filteredConvenio: any;
  public filteredPagaduria: any;

  dataSource: any;
  numeroCarpeta: string = '';
  numeroSolicitud: string = '';
  infoSolicitud: any;
  mostrarBotonConsultaMotor = false;
  mostrarConsultaMotor = false;
  responseCarpeta = 0;

  estadoColor: any;
  estadoIcono: SafeHtml;
  svgContainer1: any;

  @Input() datosCarpeta: any;

  @ViewChild(PdfViewerComponent) pdfViewer?: PdfViewerComponent;
  page = 1;
  zoom = 1;

  constructor(
    private busService: BuscCarpetaService,
    private router: Router,
    private dialogService: DialogService,
    public data: DataService,
    private getofrtaServ: GenOfertaService,
    private pagaduriaService: Pagaduriaservice,
    private reconocerSrvc: ReconocerService,
    private convenioService: ConvenioService,
    private http: HttpClient,
    private resumenAdoService: ResumenAdoService,
    private getinfoServ: GetInformacionService,
    private perfilamientoService: PerfilamientoService,
    public dialog: MatDialog,
    private motorService: MotorService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.data.setters();
    this.getConvenios();
  }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.pageNumber = e.pageIndex + 1;
  }

  async buscar(dataBusqueda) {
    this.page_number = 0;
    this.searching = true;
    this.tipoBusqueda = dataBusqueda;

    switch (this.tipoBusqueda) {
      case 'cedula':
        let numeroDocumento = parseInt(this.entradabusqueda);
        this.exeBusqueda({ numeroDocumento });
        break;
      case 'pagaduria':
        let codigoPagaduria = parseInt(this.pagaduria);
        this.exeBusqueda({ codigoPagaduria });
        break;
      case 'nombre':
        let nombreCliente = this.entradabusqueda.toString();
        this.exeBusqueda({ nombreCliente });
        break;
      case 'apellido':
        let primerApellido = this.entradabusqueda;
        this.exeBusqueda({ primerApellido });
        break;
      case 'numsolicitud':
        let numeroSolicitud = parseInt(this.entradabusqueda);
        this.exeBusqueda({ numeroSolicitud });
        break;
      case 'numcarpeta':
        let numeroCarpeta = parseInt(this.entradabusqueda);
        this.exeBusqueda({ numeroCarpeta });
        break;
      case 'fecha':
        const {
          year: initYear,
          month: initMonth,
          day: initDay,
        } = this.fechaInicial;
        const {
          year: lastYear,
          month: lastMonth,
          day: lastDay,
        } = this.fechaFinal;

        const initMonthCom = initMonth < 10 ? `0${initMonth}` : `${initMonth}`;
        const initDayCom = initDay < 10 ? `0${initDay}` : `${initDay}`;
        const initDate = `${initYear}-${initMonthCom}-${initDayCom}`;
        const lastMonthCom = lastMonth < 10 ? `0${lastMonth}` : `${lastMonth}`;
        const lastDayCom = lastDay < 10 ? `0${lastDay}` : `${lastDay}`;
        const lastDate = `${lastYear}-${lastMonthCom}-${lastDayCom}`;

        this.exeBusqueda({ fechaInicial: initDate, fechaFinal: lastDate });
        break;
    }
  }

  private exeBusqueda(value: any): void {
    this.data.setters();
    this.busService.getResultadosBusqueda(value).subscribe(
      async (res) => {
        if (res.count == 0) {
          this.dialogService.openDialog(
            'Advertencia',
            'No se encontraron resultados',
            false
          );
          this.searching = false;
          return;
        }
        this.resultadosBusqueda = res.rows;
        this.searching = false;
        res.rows[0].estadoLaboral == 'PENSIONADO'
          ? (this.data.estado = '2')
          : (this.data.estado = '1');
        this.totalPages();
        this.getConvenios();
        // console.log('NumCar', res.rows[0].numeroCarpeta);
        this.estado = 'resultados';
        this.data.pagaduriaRef = res.rows[0].codigoPagaduria;
        this.responseCarpeta = res.rows[0].numeroCarpeta;
        this.getMotorDecision(this.responseCarpeta);
        this.getofrtaServ
          .genOferta(res.rows[0].numeroCarpeta)
          .subscribe((resOf) => {
            this.genOf = resOf;
            console.log(':: Gen Ofert', this.genOf);
          });

        await this.getComprobantesCartera();
      },
      (err) => {
        this.dialogService.openDialog('Advertencia', err.error.mensaje, false);
        this.searching = false;
      }
    );
  }

  private totalPages(): void {
    this.total_pages = this.resultadosBusqueda.length;
    if (this.total_pages > 1 && this.total_pages > 10) {
      this.arrayTotal = new Array(Math.round(10));
      this.activePage = true;
    } else {
      this.arrayTotal = new Array(Math.round(this.total_pages));
    }
  }

  morePages() {
    this.contPages = this.contPages = 10;
  }

  public nextPage(): void {
    if (this.page_number < this.total_pages - 1) {
      console.log(this.page_number);
      this.page_number++;
    }
  }

  public prevPage(): void {
    if (this.page_number > 0) {
      console.log(this.page_number);
      this.page_number--;
    }
  }

  public irFormulario(numeroSolicitud: any, numeroCarpeta: any): void {
    this.router.navigateByUrl('/formulario-perfilamiento', {
      state: { numeroSolicitud, numeroCarpeta },
    });
  }

  public getConvenios() {
    this.convenioService.getConvenios().subscribe((convenios) => {
      this.convenios = convenios.rows;
      console.log('::Convenios', convenios);
      this.filteredConvenio = this.convenios.slice();
    });
  }

  getPagadurias(convenio: any) {
    console.log(convenio);
    this.pagaduriaService.getPagadurias(convenio).subscribe((pagaduria) => {
      this.pagaduarias = pagaduria.rows;
      console.log(':: Pagadurias', this.pagaduarias);
      this.filteredPagaduria = this.pagaduarias.slice();
    });
  }

  async goStep(data) {
    this.isLoading = true;

    this.data.numeroCarpeta = data.numeroCarpeta;
    this.data.numeroSolicitud = data.numeroSolicitud;
    this.data.numeroIdentificacion = data.numeroDocumento;
    this.data.cedula = data.numeroDocumento;
    this.data.apellido = data.primerApellido;
    this.data.isResume = true;

    const response = await this.reconocerSrvc
      .reconoce(data.numeroSolicitud)
      .toPromise();

    this.data.setListadoCelulares(response.celulares);
    this.data.setListadoCorreos(response.correos);

    console.log({ data });

    this.resumenAdoService.getResumenAd(this.data.numeroCarpeta).subscribe(
      async (resp: any) => {
        if (resp.marcaAuditoria === 1) {
          this.dialogService.openDialog(
            'Advertencia',
            'Carpeta se encuentra en validación. Intente mas tarde',
            false
          );
          return;
        }

        switch (data.codigoEstado) {
          case 0:
            this.router.navigateByUrl('/autorizacioncentral', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 20:
            this.getinfoServ
              .getInfoClient({ carpeta: this.data.numeroCarpeta.toString() })
              .subscribe(async (resp) => {
                this.data.cellphone =
                  resp.data.resultado.infoClient.celular.toString();

                const [tries] = resp.data.resultado.tries.filter(
                  (el) => el.tipoValidacion === 4
                );
                if (tries.intentosActuales === tries.totalIntentos) {
                  this.data.isResume = false;
                }

                this.router.navigateByUrl(
                  '/' + resp.data.resultado.logClient.status_process.viewRoute,
                  {
                    state: { resp },
                  }
                );
              });
            break;
          case 30:
            this.router.navigateByUrl('/formulario-perfilamiento', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 40:
            this.router.navigateByUrl('/formulario-perfilamiento', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 100:
            this.router.navigateByUrl('/formulario-perfilamiento', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 50:
            this.router.navigateByUrl('/rob-analisis', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 150:
            this.router.navigateByUrl('/pre-oferta', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 200:
            this.router.navigateByUrl('/cargadocumentos', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 300:
            if (resp.seizPagaduria === 1) {
              this.router.navigateByUrl('/seiz/oferta', {
                state: { data },
              });
              break;
            }
            this.router.navigateByUrl('/informacionadicional', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 600:
            this.router.navigateByUrl('/saldo', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 700:
            this.router.navigateByUrl('/informacionadicional', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 1300:
            this.router.navigateByUrl('/pazysalvo', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 1200:
            this.estado = 'resumen';
            break;
          case 530:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 960:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 970:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 531:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 990:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 980:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 550:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;

          default:
            this.dialogService.openDialog(
              'Búsqueda de cartera',
              `La carpeta se encuentra ${data.estado}`,
              false
            );
            this.isLoading = false;
            break;
        }
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  public resetMessage() {
    this.http
      .post(`${environment.ApiConecta}resend/message`, {
        numeroCarpeta: this.resultadoDetalle.numeroCarpeta,
        status: this.resultadoDetalle.codigoEstado,
      })
      .subscribe(
        (http: any) => {
          this.http = http;
          let value = http.url;
          if (http.status === 200) {
            Swal.fire({
              confirmButtonColor: '#009BDC',
              html: `<b>${http.message}</b><br>Si a tu cliente no le llegó el sms o correo, por favor copia y pega la siguiente URL y compártela con tu cliente:<br>
            <textarea id="clipboard-example-textarea" style="width: 24rem; height: 9rem;">${value}</textarea>`,
              // text: `${http.message}, Si a tu cliente no le llegó el sms o correo, por favor copia y pega la siguiente URL y compártela con tu cliente: ${http.url}`, <button [cdkCopyToClipboard]='${value}'>Copy to clipboard</button>
              titleText: 'OK',
              icon: 'success',
            });
          } else {
            this.dialogService.openDialog(
              'Advertencia',
              `${http.error.message[0].msg}`,
              false
            );
          }
        },
        (err) => {
          this.dialogService.openDialog(
            'Advertencia',
            `${err.error.message}`,
            false
          );
        }
      );
  }

  handlerMostrarMotor() {
    let state = this.activatedRoute.paramMap.pipe(
      map(() => window.history.state)
    );

    state.subscribe(() => {
      if (this.data.numeroCarpeta) {
        this.numeroSolicitud = this.data.numeroSolicitud;
        this.infoSolicitud = this.data.infoSolicitud;
        this.numeroCarpeta = this.data.numeroCarpeta;
      }
    });

    this.getMotorDecision(this.responseCarpeta);

    this.mostrarConsultaMotor = !this.mostrarConsultaMotor;
  }

  getMotorDecision(numeroCarpeta) {
    this.motorService.getMotor(numeroCarpeta).subscribe((res) => {
      this.dataSource = res.rows;
    });
  }

  display: string = 'none';
  listFiles: itemFile[];
  loading: boolean = false;
  fileView: boolean = false;
  fileSelected: itemFile;
  pathFile: string;

  async getComprobantesCartera() {
    const body: getFilesByFolder = {
      folder: this.responseCarpeta.toString(),
      satelital: 'buscar',
      originFolder: 4,
    };
    this.loading = true;
    await this.busService
      .obtenerComprobantesPago(body)
      .then((res) => {
        this.listFiles = res;
        console.log({ listFiles: this.listFiles });
        this.loading = false;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  openModal() {
    this.display = 'block';
  }
  onCloseHandled() {
    this.display = 'none';
  }

  selectFile(file: itemFile) {
    this.fileSelected = file;
    this.pathFile =
      'data:application/pdf;base64,' + this.fileSelected.fileBase64;

    const dialogConfig: MatDialogConfig = {
      width: '100%',
      maxWidth: '600px',
      height: 'fit-content',
      panelClass: 'no-padding',
      data: {
        document_base64: file.fileBase64,
      },
    };
    this.dialog.open(PdfViewDialogComponent, dialogConfig);
  }

  anteriorPagina() {
    if (this.page > 1) {
      this.page--;
    }
  }

  siguientePagina() {
    if (this.pdfViewer && this.pdfViewer?.pdfViewer.pagesCount > this.page) {
      this.page++;
    }
  }

  masZoom() {
    this.zoom += 0.1;
  }

  menosZoom() {
    this.zoom -= 0.1;
  }

  downloadPDF(file: string, nombreDocumento: string) {
    const link = document.createElement('a');
    link.href = file;
    link.target = '_blank';
    link.download = nombreDocumento;
    link.click();
  }
}
