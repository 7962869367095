import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-load-file-card',
  templateUrl: './load-file-card.component.html',
  styleUrls: ['./load-file-card.component.css']
})
export class LoadFileCardComponent implements OnInit {

  @Output() eliminarArchivo = new EventEmitter<void>();
  @Input() loading: boolean = false;            // Estado de carga
  @Input() customClass: string = '';            // Clases personalizadas
  @Input() fileName: string = '';
  @Input() fileSize: number = 0;

  ngOnInit() {
    setTimeout(() => {
      this.loading = false// loader de carga decorativo
    }, 2000);
  }

  eliminar() {
    this.customClass = 'salir'
    setTimeout(() => {
      this.eliminarArchivo.emit();
    }, 500);
  }

}
