import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-process-results',
  templateUrl: './process-results.component.html',
  styleUrls: ['./process-results.component.css']
})
export class ProcessResultsComponent {

  @Output() clickBoton = new EventEmitter<void>();
  @Input() titulo = '';
  @Input() contenido = '';
  @Input() color: 'success' | 'warning' | 'error' | 'loading' = 'success'; // Por defecto: success
  @Input() customClass: string = '';            // Clases personalizadas
  @Input() buttonText = '';

  clicBoton() {
    this.clickBoton.emit();
  }

}
