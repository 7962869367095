<app-spinner *ngIf="loading"></app-spinner>
<div class="contenido">
  <h1>Validación de identidad</h1>
  <h2>Datos del solicitante del crédito</h2>

  <!--Sección donde se incluyen los datos del cliente-->
  <div *ngIf="seccion == 'datos'" [formGroup]="formularioInformacion">
    <p>
      Ingresa los siguientes datos del solicitante para poder continuar con la
      validación de información ante centrales de riesgo.
    </p>
    <br />
    <div class="row">
      <div class="col-md-6">
        <div>
          <label style="margin-bottom: 5px; font-size: 18px; font-weight: bold">
            Tipo de documento
          </label>
        </div>
        <div class="radio-group">
          <div *ngFor="let id of tiposIdentificacion">
            <input type="radio" [id]="id.codigoTipoIdentificacion" name="tipoIdentificacion"
              [value]="id.codigoTipoIdentificacion" formControlName="tipoIdentificacion" />
            <label [htmlFor]="id.codigoTipoIdentificacion">{{
              id.nombre
              }}</label>
          </div>
        </div>
      </div>
    </div>
<br>
    <div class="row">
      <div class=" n-doc input-dcontainer">
        <mat-label style="font-weight: bold; font-size: 18px">Número de documento
        </mat-label>
        <mat-form-field appearance="outline">
          <input formControlName="numeroIdentificacion" matInput required type="number" />
          <mat-error class="error" *ngIf="
              formularioInformacion.controls.numeroIdentificacion.hasError(
                'required'
              )
            ">
            Este campo es obligatorio
          </mat-error>
        </mat-form-field>
      </div>
      <div class="dc-ap input-dcontainer">
        <mat-label style="font-weight: bold; font-size: 18px">Apellido
        </mat-label>
        <mat-form-field appearance="outline" class="app">
          <input formControlName="primerApellido" matInput required />
          <mat-error class="error" *ngIf="
              formularioInformacion.controls.primerApellido.hasError('required')
            ">
            Este campo es obligatorio
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="dc-cel input-dcontainer">
        <mat-label style="font-weight: bold; font-size: 18px">Celular
        </mat-label>
        <mat-form-field appearance="outline" class="phone">
          <input formControlName="celular" matInput required min="10" max="10" />
          <mat-error class="error" *ngIf="formularioInformacion.controls.celular.hasError('required')">
            Este campo es obligatorio
          </mat-error>
          <mat-error class="error" *ngIf="formularioInformacion.controls.celular.hasError('pattern')">
            Verifique el formato de teléfono
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <button [disabled]="loading || formularioInformacion.invalid" class="continuar btn-dc"
        (click)="guardarinfoPersonal()" mat-button>
        <mat-icon *ngIf="loading">
          <mat-spinner diameter="25"></mat-spinner>
        </mat-icon>
        Continuar
      </button>

      <button [disabled]="loading || formularioInformacion.invalid" class="m-bot" (click)="guardarinfoPersonal()"
        mat-button>
        <mat-icon *ngIf="loading" style="margin-right: 5px">
          <mat-spinner diameter="25"></mat-spinner>
        </mat-icon>
        Aceptar y continuar
      </button>
    </div>
  </div>

  <!--Sección donde se listan y selecciona el telefono al cual sera enviado el sms-->
  <div *ngIf="seccion == 'telefonos'">
    <p>
      Selecciona el número de teléfono del solicitante con el cual deseas
      realizar el proceso de autorización de consulta en centrales de riesgo.
    </p>
    <div>
      <mat-radio-group [(ngModel)]="telefono" aria-labelledby="example-radio-group-label" class="telefono-group">
        <mat-radio-button class="telefono-button" *ngFor="let celular of celulares; let i = index"
          [value]="celular.celular">
          {{ celular.celular }}
        </mat-radio-button>
      </mat-radio-group>

      <button *ngIf="haveCellphones" class="continuar btn-dc" [disabled]="telefono == '' || loading" mat-button
        (click)="goToCentralRiskAuthorization(telefono)">
        <mat-icon *ngIf="loading">
          <mat-spinner diameter="25"></mat-spinner>
        </mat-icon>
        Continuar
      </button>

      <!-- Boton sino hay celulares -->
      <button *ngIf="!haveCellphones" class="continuar btn-dc" mat-button
        (click)="goToCentralRiskAuthorization(telefono)">
        <mat-icon *ngIf="loading">
          <mat-spinner diameter="25"></mat-spinner>
        </mat-icon>
        Continuar
      </button>

      <button class="m-bot" [disabled]="telefono == '' || loading" mat-button
        (click)="goToCentralRiskAuthorization(telefono)">
        <mat-icon *ngIf="loading">
          <mat-spinner diameter="25"></mat-spinner>
        </mat-icon>
        Aceptar y continuar
      </button>
    </div>
  </div>
</div>
