<app-main-container header="Carga de documentos"
  subheader="Los siguientes documentos son indispensables para continuar con la solicitud de crédito">
  <app-stepper *ngIf="rules1step.length>0" [steps]="steps" [sucessStep]="true" [completeStep]="valido" (changeStep)="cambioStep($event)">
    <ng-template>
      <app-multiple-load-files [rules]="rules1step" [files]="files1step"></app-multiple-load-files>
    </ng-template>
    <ng-template>
      <app-multiple-load-files [rules]="rules" [files]="files2step"></app-multiple-load-files>
    </ng-template>
    <ng-template *ngIf="files3step.length > 0">
      <app-multiple-load-files [rules]="rules" [files]="files3step"></app-multiple-load-files>
    </ng-template>
    <ng-template>
      <app-multiple-load-files [rules]="rules" [files]="files4step"></app-multiple-load-files>
      <h5 class="mb-3 mt-5 text-center">¿Necesitas cargar documentos adicionales?</h5>
      <div class="d-flex justify-content-center pb-5">
        <app-multiple-file-upload-card-dotted [title]="step4adicionalsConfig.title"
          [subtitle]="step4adicionalsConfig.subtitle" [documents]="step4adicionalsConfig.step4AdicionalDocuments"
          [secuencia]="step4adicionalsConfig.secuencia"></app-multiple-file-upload-card-dotted>
      </div>
    </ng-template>
    <ng-template>
      <app-process-results [titulo]="finalResultConfig.titulo" [contenido]="finalResultConfig.contenido"
        [buttonText]="finalResultConfig.buttonText" [color]="finalResultConfig.color" (clickBoton)="cerrar()"></app-process-results>
    </ng-template>
  </app-stepper>
</app-main-container>
