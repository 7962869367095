import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { GetInformacionService } from '../../services/perfilamiento/get-informacion.service';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-registro-correo',
  templateUrl: './registro-correo.component.html',
  styleUrls: ['./registro-correo.component.css'],
})
export class RegistroCorreoComponent implements OnInit {
  public isValidConfirmar: boolean = false;
  private debounceTime?: NodeJS.Timeout;
  public formInfoCorreo: FormGroup;
  loading: boolean = false;
  intentosContinuar = 0;

  constructor(
    private router: Router,
    private dialogServ: DialogService,
    private data: DataService,
    private getinfoServ: GetInformacionService,
  ) {
    this.formInfoCorreo = new FormGroup({
      correo: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(
          /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
        ),
      ]),
      confirmarCorreo: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(
          /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
        ),
      ]),
    });
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    this.dialogServ.openDialog('Función no permitida', 'Ingresa manualmente el correo electrónico', false);
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    this.dialogServ.openDialog('Función no permitida', 'No es permitido copiar el contenido seleccionado', false);
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    this.dialogServ.openDialog('Función no permitida', 'No es permitido cortar el contenido seleccionado', false);
    e.preventDefault();
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  ngAfterViewInit(): void {
    const article = document.querySelector('article');
    if (article) {
      article.classList.remove('bg-blue-gradient-bottom');
    }
    this.data.isResume = false;
  }
  ngOnDestroy() {
    const article = document.querySelector('article');
    if (article) {
      article.classList.add('bg-blue-gradient-bottom');
    }
  }

  modalAuto(message = '') {
    this.dialogServ.openDialog('Correo incorrecto', message, false, 'Volver a intentar');
  }

  saveEmail() {
    const { correo } = this.formInfoCorreo.value;

    this.getinfoServ
      .saveCascadaMail({
        ind: 2,
        correo: correo,
        carpeta: this.data.numeroCarpeta.toString(),
        cedula: this.data.cedula.toString(),
      })
      .toPromise()
      .then(async (resp) => {
        this.data.isNewEmail = true;
        this.router.navigateByUrl('/' + resp.data.ruta);
      })
      .catch((err) => {
        this.modalAuto(err.error.message);
        this.intentosContinuar = 0;
      })
      .finally(() => {
        this.loading = false;
      });

    return true;
  }

  changeComfirmar(query) {
    const { correo } = this.formInfoCorreo.value;

    if (this.debounceTime) clearTimeout(this.debounceTime);
    this.debounceTime = setTimeout(() => {
      if (correo == query) {
        this.isValidConfirmar = false;
      } else {
        this.isValidConfirmar = true;
      }
    }, 500);
  }

  confirmacion() {
    this.loading = true;
    try {
      if (this.intentosContinuar != 0) {
        return;
      }
      this.intentosContinuar = 1;
      const { correo } = this.formInfoCorreo.value;
      this.dialogServ.openDialog(
        'Confirmación',
        `¿Estás seguro que <strong>${correo}</strong> es el correo electrónico que deseas registrar?`,
        false,
        'Si, guardar',
        ()=>{
          this.loading = true;
          this.saveEmail();
        },
        true,
        "No, ajustar",
        ()=>{
          this.loading = false;
          this.intentosContinuar = 0;
        });
    } catch (error) {
      this.modalAuto(error.error.message);
      this.loading = false;
      this.intentosContinuar = 0;
    }
  }
}
