import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TiposIdentificacionService } from 'src/app/services/catalogos/tipos-identificacion.service';
import { DialogService } from 'src/app/services/dialog.service';
import { GenOfertaService } from 'src/app/services/genOferta/gen-oferta.service';
import { CambioestadocarpetaService } from 'src/app/services/perfilamiento/cambioestadocarpeta.service';
import { PerfilamientoService } from 'src/app/services/perfilamiento/perf-inf-personal.service';
import { ReconocerService } from 'src/app/services/preOferta/reconocer.service';
import { DataService } from '../../../services/data.service';
import { Identificacion } from 'src/app/models/identificacion';
import { VigenteFondeo } from 'src/app/services/vigenteFondeo.service';
import { environment } from 'src/environments/environment';
import { GetInformacionService } from '../../../services/perfilamiento/get-informacion.service';

@Component({
  selector: 'app-datos-cliente',
  templateUrl: './datos-cliente.component.html',
  styleUrls: ['./datos-cliente.component.scss'],
})
export class DatosClienteComponent implements OnInit {
  seccion = 'datos';
  tiposIdentificacion: Array<Identificacion> = [];
  numeroSolicitud: number = 0;
  formularioInformacion: FormGroup;
  celulares: Array<any> = [];
  telefono = '';
  loading: boolean = false;
  haveCellphones: boolean = true;
  fechaExpedicion: string = '';
  apellido: string = '';
  cedula: number;
  codReconocer: string;
  celularesRec: Array<any> = [];
  intentosContinuar = 0;

  constructor(
    private identService: TiposIdentificacionService,
    private formBuilder: FormBuilder,
    private infoPersonalservice: PerfilamientoService,
    private reconocerSrvc: ReconocerService,
    private router: Router,
    private dialogServ: DialogService,
    public data: DataService,
    private cambioEstadoServ: CambioestadocarpetaService,
    private geOfertaSrvc: GenOfertaService,
    private getinfoServ: GetInformacionService,
    private vigenteFondeo: VigenteFondeo
  ) {
    this.formularioInformacion = this.formBuilder.group({
      tipoIdentificacion: ['', Validators.required],
      numeroIdentificacion: ['', Validators.required],
      primerApellido: ['', Validators.required],
      celular: ['', [Validators.required, Validators.pattern('3[0-9]{9}')]],
    });
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  ngAfterViewInit(): void {
    const article = document.querySelector('article');
    if (article) {
      article.classList.remove('bg-blue-gradient-bottom');
    }
    console.log({ env: process.env.NODE_ENV });
    this.getTipoIdentificacion();
    this.data.setResetValueInitial();
  }

  ngOnDestroy() {
    const article = document.querySelector('article');
    if (article) {
      article.classList.add('bg-blue-gradient-bottom');
    }
  }

  modalReintento(res) {
    this.dialogServ.openDialog(
      "Advertencia",
      "No obtuvimos respuesta del servicio en este momento. <br/> Intenta nuevamente y si no obtienes respuesta, reintenta en unos minutos.",
      false,
      "Reintentar",
      () => {
        this.loading = false;
        this.reconocer(res);
        this.dialogServ.closeDialog();
      },
      true,
      "Salir",
      () => {
        this.router.navigateByUrl('/home');
      }
    )
  }

  public async guardarinfoPersonal(): Promise<void> {
    try {
      if (this.intentosContinuar != 0) {
        return;
      }
      this.intentosContinuar = 1;

      this.data.setters();
      this.loading = true;

      const numCelular = this.formularioInformacion.get('celular').value;
      localStorage.setItem('celular', numCelular);

      const { numeroIdentificacion } = this.formularioInformacion.value;

      const { fondeador } = await this.vigenteFondeo
        .validacionFondeadores({
          identificacion: numeroIdentificacion,
        })
        .toPromise();

      if (fondeador) {
        this.dialogServ.openDialog(
          'Advertencia',
          `El cliente no se puede refinanciar. Se puede tramitar como nuevo, si aplica.`,
          false,
          'Aceptar',
          null,
          true,
          'Desistir',
          () => {
            this.router.navigateByUrl('/home');
          }
        )
      }

      if (this.formularioInformacion.valid) {
        let datosForm = this.formularioInformacion.value;
        datosForm.numeroSolicitud = 0;

        this.infoPersonalservice.guardarPerfil(datosForm).subscribe(
          (res) => {
            if (res.code) {
              this.dialogServ.openDialog(
                'Ocurrió un problema',
                res.message,
                false
              );
            } else {
              const { primerApellido, numeroIdentificacion, celular } =
                this.formularioInformacion.value;

              this.data.numeroCarpeta = res.numeroCarpetaWfc;
              this.data.numeroSolicitud = res.codigoSolicitud;
              this.data.cedula = numeroIdentificacion;
              this.data.apellido = primerApellido;
              this.data.cellphone = celular;
              this.data.fechaExpedicion = res.fechaExpedicion;

              this.getinfoServ
                .saveCascadeClient({
                  carpeta: this.data.numeroCarpeta,
                  identificacion: this.data.cedula.toString(),
                  celular: Number.parseInt(this.data.cellphone),
                  apellido: this.data.apellido,
                  esEvidente: 0,
                })
                .subscribe(
                  () => {
                    this.reconocer(res);
                  },
                  async (error) => {
                    this.intentosContinuar = 0;
                    this.loading = false;
                    this.modalAuto(error.error.message);

                    await this.cambioEstadoServ
                      .cambioEstadocarpeta(
                        parseInt(this.data.numeroCarpeta),
                        '900000',
                        error.error.message
                      )
                      .toPromise();
                  }
                );
            }
          },
          (err) => {
            this.intentosContinuar = 0;
            let message = err.error.message;

            if (
              err.error.message.includes('LISTAS_NEGRAS') ||
              err.error.message.includes('listas restrictivas')
            ) {
              message = 'Cliente no sujeto a credito';
            }
            this.dialogServ.openDialog('Ocurrió un problema', message, false);

            this.geOfertaSrvc.rechazarOferta(
              this.data.numeroCarpeta,
              '10',
              'Rechazo por documentación incompleta'
            );
          }
        );
      }
    } catch (error) {
      console.log({ error });
      this.intentosContinuar = 0;
      this.loading = false;
    }
  }

  private reconocer(res) {
    this.reconocerSrvc.reconoce(res.codigoSolicitud).subscribe(
      async (rest) => {
        if (this.validarPosibleErrorReconocer(rest)) {
          await this.cambioEstadoServ
            .cambioEstadocarpeta(
              parseInt(this.data.numeroCarpeta),
              '900000',
              'Cambio de estado aprobado por el Asesor'
            )
            .toPromise();
          this.loading = false;
          return;
        }

        this.cambioEstadoServ
          .cambioEstadocarpeta(
            Number(this.data.numeroCarpeta),
            '20',
            'Cambio de estado aprobado por el Asesor'
          )
          .subscribe(
            (res) => {
              console.log({ resCambioEstado: res });
            },
            (err) => {
              this.intentosContinuar = 0;
              this.loading = false;
              this.dialogServ.openDialog(
                'Ocurrió un problema',
                err.error.message,
                false
              );
            }
          );
        this.celularesRec = rest.celulares ? rest.celulares : [];
        this.celulares = rest.celulares ?? [];
        let celulares = rest.celulares ? rest.celulares : [];
        let correos = rest.correos ? rest.correos : [];
        this.getinfoServ
          .validatePhoneReconocer({
            celulares,
            correos,
            carpeta: this.data.numeroCarpeta,
          })
          .subscribe(
            (respuesta) => {
              this.data.setListadoCelulares(this.celularesRec);
              this.data.setListadoCorreos(correos);
              if (respuesta.data.resultado.mensaje === 'Celular encontrado') {
                this.modalAuto(
                  'El celular está asociado a otro solicitante, verifica la información e intenta de nuevo.'
                );
              }
              this.router.navigateByUrl('/' + respuesta.data.ruta);
              if (this.celulares.length == 0) {
                this.modalNoregistro();
              }
            },
            async (error) => {
              this.modalAuto(error.error.message);
              this.intentosContinuar = 0;
              this.loading = false;
              console.log('Falle validacion celular' + error);
              this.intentosContinuar = 0;
              await this.cambioEstadoServ
                .cambioEstadocarpeta(
                  parseInt(this.data.numeroCarpeta),
                  '900000',
                  error.error.message
                )
                .toPromise();
            }
          );
      },
      (error) => {
        this.modalReintento(res);
        this.intentosContinuar = 0;
        console.log('Falle Reconocer' + error);
      }
    );
  }

  private getTipoIdentificacion() {
    this.identService.getTipoIdentificacion().subscribe((res) => {
      this.tiposIdentificacion = res.rows;
      this.formularioInformacion.patchValue({
        tipoIdentificacion: res.rows[0].codigoTipoIdentificacion,
      });
      this.tiposIdentificacion = this.tiposIdentificacion.slice(0, 2);
    });
  }

  public async goToCentralRiskAuthorization(celular: string): Promise<void> {
    const numCelular = localStorage.getItem('celular');
    if (celular !== '') {
      this.data.cellphone = celular;

      if (environment.production) {
        this.getinfoServ
          .savePhone({
            phone: celular,
            folder_id: this.data.numeroCarpeta,
            origin_phone: 'reconocer',
          })
          .subscribe((res) => {
            console.log('update phone in db', res);
          });
        localStorage.setItem('celular', celular);
      }
    } else {
      this.data.cellphone = numCelular;
    }

  }

  modalNoregistro() {
    this.dialogServ.openDialog("No registra información del solicitante", "Puedes diligenciar los datos a continuación.", false)
  }

  validarPosibleErrorReconocer(data: any) {
    this.intentosContinuar = 0;
    if (!data.descReconocer) {
      return false;
    }

    const carpeta = this.data.numeroCarpeta;
    const mensajeReconocer = data.descReconocer.split('|');

    let codigoReconocer = data.codReconocer;
    if (mensajeReconocer.length > 1) {
      codigoReconocer = mensajeReconocer[0];
    }

    if (codigoReconocer === '02') {
      this.dialogServ.openDialog(
        'Ocurrió un problema',
        `Error Proveedor - Reporte incidente Codigo 02. Espere respuesta para perfilar de nuevo. La carpeta ${carpeta} fue rechazada`,
        false
      );
      return true;
    }
    if (codigoReconocer === '04') {
      this.dialogServ.openDialog(
        'Ocurrió un problema',
        `La carpeta ${carpeta} fue rechazada. Verifica el tipo de documento del cliente y registra una nueva carpeta.`,
        false
      );
      return true;
    }
    if (codigoReconocer === '05') {
      this.dialogServ.openDialog(
        'Ocurrió un problema',
        `La carpeta ${carpeta} fue rechazada. Verifica el numero del documento del cliente y registra una nueva carpeta.`,
        false
      );
      return true;
    }
    if (codigoReconocer === '06') {
      this.dialogServ.openDialog(
        'Ocurrió un problema',
        `La carpeta ${carpeta} fue rechazada. Verifica que sea el primer apellido del cliente y registra una nueva carpeta.`,
        false
      );
      return true;
    }
    if (codigoReconocer === '09') {
      this.dialogServ.openDialog(
        'Ocurrió un problema',
        `La carpeta ${carpeta} fue rechazada. El cliente no se puede perfilar. El cliente debe gestionar actualización ante centrales de riesgo.`,
        false
      );
      return true;
    }
    if (codigoReconocer === '10') {
      this.dialogServ.openDialog(
        'Ocurrió un problema',
        'Apellido no coincide con el registrado en la registraduria no puedes continuar. Verificalo',
        false
      );
      return true;
    }
    if (codigoReconocer === '12') {
      this.dialogServ.openDialog(
        'Ocurrió un problema',
        `Error Proveedor - Reporte incidente Codigo 12. Espere respuesta para perfilar de nuevo. La carpeta ${carpeta} fue rechazada`,
        false
      );
      return true;
    }
    if (codigoReconocer === '14') {
      this.dialogServ.openDialog(
        'Ocurrió un problema',
        `La carpeta ${carpeta} fue rechazada. El cliente no es sujeto de crédito.`,
        false
      );
      return true;
    }
    if (codigoReconocer === '17') {
      this.dialogServ.openDialog(
        'Ocurrió un problema',
        `Error Proveedor - Reporte incidente Codigo 17. Espere respuesta para perfilar de nuevo. La carpeta ${carpeta} fue rechazada`,
        false
      );
      return true;
    }
    if (codigoReconocer === '25') {
      this.dialogServ.openDialog(
        'Ocurrió un problema',
        `Error Proveedor - Reporte incidente Codigo 25. Espere respuesta para perfilar de nuevo. La carpeta ${carpeta} fue rechazada`,
        false
      );
      return true;
    }
    if (codigoReconocer === '26') {
      this.dialogServ.openDialog(
        'Ocurrió un problema',
        `Error Proveedor - Reporte incidente Codigo 26. Espere respuesta para perfilar de nuevo. La carpeta ${carpeta} fue rechazada`,
        false
      );
      return true;
    }

    return false;
  }

  async rechazarCarpeta(carpeta) {
    await this.cambioEstadoServ
      .cambioEstadocarpeta(Number(carpeta), '900000', 'Rechazo por asesor')
      .toPromise();
  }

  modalAuto(message = '') {
    this.dialogServ.openDialog('Error', message, false)
  }
}
