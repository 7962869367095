import { Injectable } from '@angular/core';
import { DialogComponent } from '../components/dialogs/standar/dialog.component';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingComponent } from '../components/dialogs/loading/loading.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private modalRef: NgbModalRef | null = null;

  constructor(private modalService: NgbModal) {}

  // Dialogos por defecto
  openDialog(
    titulo: string,
    mensaje: string,
    success: boolean,
    textNextButton?: string,
    next: () => any = () => {},
    backButton?: boolean,
    textBackButton?: string,
    back: () => any = () => {}
  ) {
    this.modalRef = this.modalService.open(DialogComponent, {
      centered: true,
      backdrop: 'static',
      backdropClass: 'custom-backdrop',
      windowClass: 'custom-modal',
    });
    this.modalRef.componentInstance.titulo = titulo;
    this.modalRef.componentInstance.mensaje = mensaje;
    this.modalRef.componentInstance.success = success;
    if (textNextButton) this.modalRef.componentInstance.textNextButton = textNextButton;
    if (textBackButton) this.modalRef.componentInstance.textBackButton = textBackButton;
    this.modalRef.componentInstance.backButton = backButton;

    this.modalRef.closed.subscribe(() => {
      next();
      this.modalRef = null; // Limpiamos la referencia después de cerrar el modal
    });

    this.modalRef.dismissed.subscribe(() => {
      back();
      this.modalRef = null; // Limpiamos la referencia después de cerrar el modal
    });
  }

  // Dialogos de carga con textos
  openDialogLoadingService(
    titulo?: string,
    mensaje?: string) {
    this.modalRef = this.modalService.open(LoadingComponent, {
      centered: true,
      backdrop: 'static',
      backdropClass: 'custom-backdrop',
      windowClass: 'custom-modal-loading'
    });
    if (titulo) this.modalRef.componentInstance.titulo = titulo;
    if (mensaje) this.modalRef.componentInstance.mensaje = mensaje;
  }

  closeDialog() {
    if (this.modalRef) {
      this.modalRef.close(); // Cerramos el modal
      this.modalRef = null; // Limpiamos la referencia
    }
  }

  dismissDialog() {
    if (this.modalRef) {
      this.modalRef.dismiss(); // Descartamos el modal
      this.modalRef = null; // Limpiamos la referencia
    }
  }
}
