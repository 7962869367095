<main class="w-full my-auto sm:pt-6 flex justify-content-center align-items-center text-white">

  <app-confirmada class="mx-auto pt-8 mt-4 w-9 sm:w-7 lg:w-5" *ngIf="changedPassword; else elseBlock"></app-confirmada>

  <ng-template #elseBlock>
    <form [formGroup]="passwordForm" (ngSubmit)="actRc()" autocomplete="off"
      class="mt-0 w-10 sm:w-7 lg:w-5 px-3 py-4 border-round-md border-sky-20 item">
      <header class="mt-2 mb-4">
        <p class=" px-2 text-xl font-bold text-white">
          Cambio de contraseña
        </p>
      </header>
      <label for="old-password" class="px-2 block">
        <strong class=" text-base mb-2 block" style="font-size: 15px !important;">
          Contraseña actual
        </strong>
        <p-password formControlName="old-password" type="text" placeholder="*contraseña" required [toggleMask]="true"
          [feedback]="false" inputStyleClass="max-w-none w-full" styleClass="max-w-none w-full"></p-password>
      </label>
      <span *ngIf="incorrectPassword" class="p-2 text-sm text-red-600">
        Contraseña incorrecta
      </span>
      <hr class="hidden sm:block bg-sky">
      <label for="new-password" class="px-2 block">
        <strong class=" text-base mb-2 block" style="font-size: 15px !important;">
          Nueva contraseña
        </strong>
        <p-password formControlName="new-password" type="text" placeholder="*contraseña" required [toggleMask]="true"
          [feedback]="false" inputStyleClass="max-w-none w-full" styleClass="max-w-none w-full"></p-password>
      </label>
      <div class="row">
        <div class="col-md-6">
          <span class="mb-2 block fw-semibold" *ngIf="passwordnew.value"
            [class]="passwordnew.hasError('minlength') ? 'text-gray-400' : 'text-lime'">
             <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
             <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
            </svg> &nbsp; -->
            Mínimo 8 caracteres
          </span>
        </div>
        <div class="col-md-6">
          <span class="mb-2 block fw-semibold" *ngIf="passwordnew.value"
            [class]="passwordnew.hasError('notMayus') ? 'text-gray-400' : 'text-lime'">
            <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
            Mínimo 1 letra mayúscula
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <span class="mb-2 block fw-semibold" *ngIf="passwordnew.value"
            [class]="passwordnew.hasError('notMinus') ? 'text-gray-400' : 'text-lime'">
            <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
            Mínimo 1 letra minúscula
          </span>
        </div>
        <div class="col-md-6">
          <span class="mb-2 block fw-semibold" *ngIf="passwordnew.value"
            [class]="passwordnew.hasError('notNumber') ? 'text-gray-400' : 'text-lime'">
            <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
            Mínimo 1 número
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <span class="mb-2 block fw-semibold" *ngIf="passwordnew.value"
            [class]="passwordnew.hasError('notSpecialCharacter') ? 'text-gray-400' : 'text-lime'">
            <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
            Mínimo 1 un caracter especial (+*%$)
          </span>
        </div>
      </div>

      <label for="confirm-password" class="px-2 block">
        <strong class=" text-base my-2 block" style="font-size: 15px !important;">
          Confirmar nueva contraseña
        </strong>
        <p-password formControlName="confirm-password" type="text" placeholder="*contraseña" required
          [toggleMask]="true" [feedback]="false" inputStyleClass="max-w-none w-full"
          styleClass="max-w-none w-full"></p-password>
      </label>
      <div *ngIf="passwordconfirm.value && passwordForm.errors?.notEqual" class="px-3 mb-2 block text-xs text-pink">
        Las contraseñas no coinciden
      </div>
      <footer class="w-full px-4 mt-4 flex align-items-center">
        <button type="submit" [disabled]="!passwordForm.valid" [class]="!passwordForm.valid && 'opacity-70'"
          class=" font-bold text-lg w-full py-2 border-none border-round-md text-gray-900 bg-sky-200">
          Cambiar contraseña
        </button>
      </footer>
      <div class="w-full my-4 text-center">
        <a routerLink="/mi-perfil" class="text-sky-200">
          <i class="fa fa-arrow-left"></i>
          Volver a mi perfil
        </a>
      </div>
    </form>
  </ng-template>
</main>
