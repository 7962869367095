import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Documento } from 'src/app/models/documento';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-multiple-file-upload-card-dotted',
  templateUrl: './multiple-file-upload-card-dotted.component.html',
  styleUrls: ['./multiple-file-upload-card-dotted.component.scss']
})
export class MultipleFileUploadCardDottedComponent{

  @Input() title: string = ""; // titulo de la tarjeta
  @Input() subtitle: string = ""; // subtitulo de la tarjeta
  @Input() documents: Documento[] = [];
  @Input() secuencia: number = 0; // secuencia para aws
  @Input() contract: boolean = false; // titulo de la tarjeta
  @Input() contrato_url: string = ''; // url del contrato
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  private util = new Utils();
  constructor() { }

  public deleteDymamic(index: number): void {
    this.documents.splice(index, 1);
  }

  getContrato() {
    window.location.href = this.contrato_url;
  }


  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      for (let i = 0; i < input.files.length; i++) {
        let archivo: Documento = { status: true, secuencia: this.secuencia };
        archivo.nombre = input.files[i].name;
        archivo.archivo = input.files[i]
        if (archivo.archivo.type != "application/pdf") {
          this.util.convertImageFileToPDF(archivo.archivo).then(file => {
            archivo.archivo = file
          })
        }
        this.documents.push(archivo)
      }
    }
  }

  public clicFileUpload(): void {
    this.fileInput.nativeElement.click();
  }
}
