<div class="main-icon d-flex align-items-center flex-column" [ngClass]="customClass">
  <div class="fondo" [ngClass]="color"></div>
  <div class="main-container d-flex align-items-center flex-column">
    <app-spinner-look-feel *ngIf="color=='loading'"></app-spinner-look-feel>
    <img *ngIf="color=='error'" class="icono" src="assets/icons/error-carga.svg" alt="" />
    <img *ngIf="color=='warning'" class="icono" src="assets/icons/inactividad.svg" alt="" />
    <img *ngIf="color=='success'" class="icono" src="assets/icons/completado.svg" alt="" />
    <h4 class="mt-5">{{titulo}}</h4>
    <p *ngIf="contenido" class="text-center mt-2" [innerHTML]="contenido"></p>
    <button-look-feel *ngIf="buttonText" class="mt-5" color="secondary" size="medium"
      (clicked)="clicBoton()">{{buttonText}}</button-look-feel>
  </div>
</div>
