<br>
<body style="overflow-x:hidden">
  <div class="container">
    <div class="row">
      <div class="col-md-9">
        <div *ngIf="showNews.url === ''" id="panel">
          <div class="circle yellow"></div>
          <div class="circle blue"></div>
          <div class="desk " style="padding-left: 15px; padding-right: 15px;">
            <h1 class="title-user">¡Hola, {{ user | titlecase }}!</h1>
            <div class="embed-wrapper">
              <iframe *ngIf="dashboard" class="embed" [src]="dashboard | safeUrl" frameborder="0"></iframe>
            </div>
          </div>
          <div class="row mobile ">
            <h1 class="title-user ">¡Hola, {{ user | titlecase }}!</h1>
            <div class="embed-wrapper">
              <iframe *ngIf="dashboard" class="embed" [src]="dashboard | safeUrl" frameborder="0"></iframe>
            </div>
          </div>
          <div class="row d-lg-flex w-100 cards-wrapper">
            <div class="col-md-6 card-wrap " style="padding: 0;">
              <div class="card-definition shadow" id="cred">
                <div class="card-head">
                  <h3 class="card-title ">Nuevo crédito</h3>
                </div>
                <div class="card-body_" align="center">
                  <p class="card-description fw-semibold">
                    Empieza el proceso de solicitud de crédito nuevo, refinanciación o
                    compra de cartera
                  </p>
                  <div [routerLink]="['/autorizacionTransferencia']"
                    class="card-btn btn-dark d-flex justify-content-center align-items-center ">
                    <span>Solicitar</span>
                  </div><br>
                </div>
              </div>
            </div>
            <div class="col-md-6 card-wrap ">
              <div class="card-definition shadow" id="carp">
                <div class="card-head">
                  <h3 class="card-title ">Mis créditos</h3>
                </div>
                <div class="card-body_" align="center">
                  <p class="card-description fw-semibold">
                    Busca las carpetas de tus clientes, por cédula, pagaduría, fecha de
                    creación, nombre y apellido o número de carpeta
                  </p>
                  <div [routerLink]="['/busqueda-carpetas']"
                    class="card-btn btn-yellow d-flex justify-content-center align-items-center ">
                    <span>Buscar carpetas</span>
                  </div><br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <!-- <div style="width: 100%; height: 100%"> -->
          <div
            style="position: absolute;height:77%;border-radius: 4px;margin-top: 48px; background: transparent; opacity: 20px;color: transparent;"
            [routerLink]="['/debes-saber/politicas']">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <iframe style="height: 100%;border-radius: 4px;" class="embed-wrapper_" *ngIf="politicas" class="embed-wrapper_" [src]="politicas | safeUrl" frameborder="0"></iframe>
        <!-- </div> -->
        <div *ngIf="showNews.url !== ''">
          <div>
            <iframe class="embed-wrapper_" [src]="showNews.url | safeUrl" [title]="showNews.name"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
