<br>
<div class="card shadow p-0  bg-body rounded" style="width: 340px;">
  <div class="row" style="text-align: center;">
    <div class="col-md-6 border-info border-right-1">
      <label class="labelT  " style="padding-top: 3%;">Fecha</label><br>
      <label style="display: inline-block; font-size: 18px;"  ><strong>{{
          datosCarpeta.fechaCreacion | date:
          "dd-MM-YYYY"}}</strong></label>
    </div>
    <div class="col-md-6">
      <label class="labelT  " style="padding-top: 3%;">Número de Carpeta</label>
      <label   style="font-size: 18px;"><strong>{{ datosCarpeta.numeroCarpeta
          }}</strong></label>
    </div>
  </div>
  <div class="row">
    <div class="alert  " role="alert"
      [style]="{'background': estadoColor, 'width':'100%', 'border-radius':'0%'}">
      <div class="row">
        <div class="col-md-2 icono" [innerHTML]="estadoIcono"></div>
        <div class="col-md-10">
          <div class="row">
            <div style="color: #181D40;text-align: left; font-size: 11px;display: inline-block;" >
              Estado actual de la carpeta
            </div>
          </div>
          <div class="row">
            <div style="color: #181D40;text-align: left; font-size: 16px;display: inline-block;"
              class="fw-semibold">
              {{ datosCarpeta.estado }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="labelT  ">Pagaduria</div>
      <div class="value fw-semibold">{{ datosCarpeta.pagaduria }}</div>
    </div>
    <div class="col-md-6">
      <div class="labelT  ">Monto</div>
      <div class="value fw-semibold">
        {{ datosCarpeta.montoSolicitado | currency }}
      </div>
    </div>
  </div><br>
  <div class="row">
    <div class="col-md-6">
      <div class="labelT  ">Valor Cuota</div>
      <div class="value fw-semibold">{{ datosCarpeta.valorCuota | currency }}</div>
    </div>
    <div class="col-md-6">
      <div class="labelT  ">Tasa de interes (1)</div>
      <div class="value fw-semibold">{{ datosCarpeta.tasaAprobada }}%</div>
    </div>
  </div><br>
  <div class="row">
    <div class="col-md-6">
      <div class="labelT  ">Plazo (2)</div>
      <div class="value fw-semibold">{{ datosCarpeta.plazoSolicitado }} meses</div>
    </div>
    <div class="col-md-6">
      <div class="labelT  ">Seguro Aplicado (3)</div>
      <div class="value fw-semibold">
        {{ datosCarpeta.planSeguroVoluntario | seguroVoluntario }}
      </div>
    </div>
  </div><br>
  <div class="row">
    <div class="col-md-6">
      <div class="labelT  ">Sucursal</div>
      <div class="value fw-semibold">
        {{ datosCarpeta.nombreSucursal | sucursal }}
      </div>
    </div>
  </div>
  <br>
  <ng-content select="[footer]"> </ng-content>
  <br>
</div>
