<div class="contenido">
  <h1>Validación de identidad</h1>
  <h2>Datos del solicitante del crédito</h2>
  <p>Para la validación de identidad del solicitante, como corredor/asesor y en calidad del responsable de la información, deberás seleccionar el celular que confirma el solicitante:
  </p>
  <br>
  <div class="row">
    <mat-radio-group
      [(ngModel)]="telefono"
      aria-labelledby="example-radio-group-label"
      class="telefono-group"
    >
      <mat-radio-button
        class="telefono-button"
        *ngFor="let celular of celulares"
        [value]="celular.celular"
      >
        {{ celular.celular }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <button
    *ngIf="haveCellphones"
    class="continuar btn-dc"
    [disabled]="telefono == '' || loading"
    mat-button
    (click)="savenumber()"
  >
    <mat-icon *ngIf="loading">
      <mat-spinner diameter="25"></mat-spinner>
    </mat-icon>
    Continuar
  </button>
</div>
