<!--app-header></app-header-->

<div class="p-4">
  <div *ngIf="seccion == 'seleccion'" id="seleccionplan">
    <h1>Selección seguro voluntario</h1><br>
    <p id="descripcion">
      Este proceso es importante para la verificación de nuestro cliente, se
      requiere alguna información por parte del cliente para finalizar el
      proceso.
    </p>

    <div class="card-body" style="max-width: 1440px">
      <h1>
        Seguro individual
      </h1>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="nplan sdow" style="width: 50px" scope="col">
                Nombre <br />
                plan
              </th>
              <th class="thBody" style="width: 70px">Valor comisión</th>
              <th class="thBody" style="width: 70px">Valor prima total</th>
              <th class="thBody" style="width: 70px">Valor prima mensual</th>
              <th class="thBody" style="width: 70px">Valor prima diaria</th>
              <th class="thBody" style="width: 70px">Monto asegurado</th>
              <th class="thBody" style="width: 50px"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dato of planesIndividuales; let i = index" class="grow"
              (click)="seleccionaPlan(dato, 'individual')" style="cursor: pointer">
              <th [style]="
                  planseleccionado == dato.codigoPlan
                    ? 'border-left: 5px solid #F9C532 !important'
                    : ''
                " [class]="i == planesIndividuales.length - 1 ? 'border' : ''" style="
                  width: 50px;
                  background-color: white;
                  border-bottom: 1px solid white;
                " class="right left sdow">
                {{ dato.detalleSeguro }}
              </th>
              <td style="width: 70px; border-left: none !important">
                $ {{ dato?.valorComision | number: "1.0-0" }}
              </td>
              <td style="width: 70px" currencyMask>
                $ {{ dato.valorSeguro | number: "1.0-0" }}
              </td>
              <td style="width: 70px" currencyMask>
                $ {{ dato.primaMensual | number: "1.0-0" }}
              </td>
              <td style="width: 70px" currencyMask>
                $ {{ dato.primaDiaria | number: "1.0-0" }}
              </td>
              <td style="width: 70px" currencyMask>
                $ {{ dato.valorCobertura | number: "1.0-0" }}
              </td>
              <td style="height: 100%">
                <div class="select-container">
                  <mat-radio-button style="padding: 0" [checked]="planseleccionado == dato.codigoPlan">
                  </mat-radio-button>
                </div>
              </td>
              <!--  <td style="width: 50px;"><div class="seguro"><input [value]="dato.codigoPlan" [(ngModel)]='planseleccionado' name="inlineRadioOptions" type="radio"></div></td> -->
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="loading" style="display: flex; justify-content: center">
        <mat-spinner diameter="70"></mat-spinner>
      </div>
    </div>
    <br>
    <div class="card-body" style="max-width: 1440px">
      <h1>
        Seguro familiar
      </h1>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="nplan sdow" style="width: 50px" scope="col">
                Nombre <br />
                plan
              </th>
              <th class="thBody" style="width: 70px">Valor comisión</th>
              <th class="thBody" style="width: 70px">Valor prima total</th>
              <th class="thBody" style="width: 70px">Valor prima mensual</th>
              <th class="thBody" style="width: 70px">Valor prima diaria</th>
              <th class="thBody" style="width: 70px">Monto asegurado</th>
              <th class="thBody" style="width: 50px"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dato of planesFamiliares; let i = index" class="grow"
              (click)="seleccionaPlan(dato, 'familiar')" style="cursor: pointer">
              <th [style]="
                  planseleccionado == dato.codigoPlan
                    ? 'border-left: 5px solid #F9C532 !important'
                    : ''
                " style="
                  width: 50px;
                  background-color: white;
                  border-bottom: 1px solid white;
                " class="right left sdow" [class]="i == planesFamiliares.length - 1 ? 'border' : ''">
                {{ dato.detalleSeguro }}
              </th>
              <td style="width: 70px">
                $ {{ dato?.valorComision | number: "1.0-0" }}
              </td>
              <td style="width: 70px">
                $ {{ dato.valorSeguro | number: "1.0-0" }}
              </td>
              <td style="width: 70px">
                $ {{ dato.primaMensual | number: "1.0-0" }}
              </td>
              <td style="width: 70px">
                $ {{ dato.primaDiaria | number: "1.0-0" }}
              </td>
              <td style="width: 70px">
                $ {{ dato.valorCobertura | number: "1.0-0" }}
              </td>
              <td style="height: 100%">
                <div class="select-container">
                  <mat-radio-button [checked]="planseleccionado == dato.codigoPlan"></mat-radio-button>
                </div>
              </td>
              <!--  <td style="width: 50px;"><div class="seguro"><input [value]="dato.codigoPlan" [(ngModel)]='planseleccionado' name="inlineRadioOptions" type="radio"></div></td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="loading" style="display: flex; justify-content: center">
        <mat-spinner diameter="70"></mat-spinner>
      </div>
      <div class="planes">
        <button (click)="regresar()" class="buttonback" mat-button>
          Regresar
        </button>
        <button class="buttoncontinue" mat-button (click)="continuar()" [disabled]="isLoading">
          Guardar y Continuar
        </button>
      </div>
    </div>
  </div>

  <div id="planfamiliar" *ngIf="seccion == 'planfamiliar'">
    <h1>Seguro voluntario familiar</h1><br>
    <p id="descripcion">
      Este proceso es importante para la verificación de nuestro cliente, se
      requiere alguna información por parte del cliente para finalizar el
      proceso.
    </p>
    <!--  <h2>Información del beneficiario principal</h2> -->
    <div>
      <h1>Información de beneficiarios</h1>
      <div>
        <div *ngFor="let asegurado of dynamicForms; let i = index">
          <form [formGroup]="asegurado">
            <div style="width: 100%">
              <div style="display: flex; justify-content: space-between">
                <mat-label>Parentesco</mat-label>
                <app-eliminar [texto]="'Eliminar asegurado'" *ngIf="i != 0" (click)="eliminarFamiliar(i)">
                </app-eliminar>
              </div>
            </div>
            <div class="row">
              <div>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="parentesco" required>
                    <mat-option [value]="parent.codigo" *ngFor="let parent of parentescos">
                      {{ parent.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="asegurado.controls.parentesco.hasError('required')">
                    Campo obligatorio
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="inp-wrap col-3 col-sm-3 col-md-3 col-lg-3 mb-err-inp">
                <mat-label>Primer nombre</mat-label>
                <mat-form-field appearance="outline">
                  <input formControlName="primerNombre" type="text" required matInput />
                  <mat-error class="error" *ngIf="asegurado.controls.primerNombre.hasError('required')">
                    Campo oblicatorio
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="inp-wrap col-3 col-sm-3 col-md-3 col-lg-3 mb-err-inp">
                <mat-label>Segundo nombre (opcional)</mat-label>
                <mat-form-field appearance="outline">
                  <input formControlName="segundoNombre" type="text" matInput />
                </mat-form-field>
              </div>
              <div class="inp-wrap col-3 col-sm-3 col-md-3 col-lg-3 mb-err-inp">
                <mat-label>Primer apellido</mat-label>
                <mat-form-field appearance="outline">
                  <input formControlName="primerApellido" type="text" matInput />
                  <mat-error class="error" *ngIf="asegurado.controls.parentesco.hasError('required')">
                    Campo obligatorio
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="inp-wrap col-3 col-sm-3 col-md-3 col-lg-3 mb-err-inp">
                <mat-label>Segundo apellido (opcional)</mat-label>
                <mat-form-field appearance="outline">
                  <input formControlName="segundoApellido" type="text" matInput />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="inp-wrap col-3 col-sm-3 col-md-3 col-lg-3 mb-err-inp">
                <mat-label>Tipo de documento</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="tipoDocumento" required>
                    <mat-option [value]="ident.codigoTipoIdentificacion" *ngFor="let ident of tiposIdentificacion">
                      {{ ident.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="asegurado.controls.parentesco.hasError('required')">
                    Campo obligatorio
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="inp-wrap col-3 col-sm-3 col-md-3 col-lg-3 mb-err-inp">
                <mat-label>Número de documento </mat-label>
                <mat-form-field appearance="outline">
                  <input formControlName="numeroDocumento" type="number" matInput />
                  <mat-error class="error" *ngIf="asegurado.controls.parentesco.hasError('required')">
                    Campo obligatorio
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="inp-wrap col-3 col-sm-3 col-md-3 col-lg-3 mb-err-inp">
                <mat-label>Fecha de nacimiento</mat-label>
                <mat-form-field style="margin-right: 30px" appearance="outline">
                  <input formControlName="fechaNacimiento" matInput [max]="hoy" [matDatepicker]="fecha3"
                    (dateChange)="onValidateDate()" />
                  <mat-datepicker-toggle matSuffix [for]="fecha3"></mat-datepicker-toggle>
                  <mat-datepicker #fecha3></mat-datepicker>
                  <mat-error class="error" *ngIf="asegurado.controls.parentesco.hasError('required')">
                    Campo obligatorio
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <a (click)="agregarAsegurado()" role="button" style="color: #009df3; text-decoration: underline">Agregar
                otro familiar</a>
            </div>
            <div *ngIf="dynamicForms.length - 1 != i" style="
                border-bottom: 1px solid gray;
                padding-bottom: 10px;
                max-width: 1140px;
                margin-bottom: 10px;
              "></div>
          </form>
        </div>
      </div><br>
      <div class="justify-right">
        <p>
          Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás aceptando
          que la información es correcta
        </p>
      </div>
      <div class="justify-right">
        <button (click)="seccion = 'seleccion'" class="buttonback" mat-button>
          Regresar
        </button>
        <button [disabled]="disabledButtonBeneficiaries" class="buttoncontinue" (click)="guardarSeleccion()" mat-button>
          Guardar y Continuar
        </button>
      </div>
    </div>
  </div>
</div>

<div data-backdrop="static" class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <img src="https://media.giphy.com/media/3o85xITplfSixzj4cg/giphy.gif" width="200" height="200" />
    </div>
  </div>
</div>
