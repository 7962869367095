<app-card-dotted-line>
  <div class="tarjeta" [ngClass]="texto?'texto':''">
    <div *ngIf="file.archivo">
      <div class="w-100 d-flex flex-row-reverse" [ngClass]="loading?'invisible':''">
        <img src="assets/icons/close-doc.svg" class="m-1" alt="" (click)="onClearFile()" />
      </div>
      <div class="d-flex align-items-center flex-column p-3">
        <div class="w-100 text-center entrada" *ngIf="!loading">
          <img class="mb-3" src="{{iconoLoaded}}" style="width: 42px" alt="" />
          <div class="fw-semibold document-name">{{nombreArchivo}}</div>
          <p class="mb-0"> {{fileSize | fileSize }}</p>
        </div>
        <div class="w-100 text-center" *ngIf="loading">
          <img class="mb-3 cargando" src="{{iconoLoading}}" style="width: 42px" alt="" />
          <app-progress></app-progress>
          <div class="fw-semibold mt-3">
            Cargando
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center flex-column p-3" *ngIf="!file.archivo">
      <img class="mt-2" src="{{iconoCentral}}" alt="" />
      <h5 class="mt-3">
        {{ titulo }}
      </h5>
      <p *ngIf="texto" class="mb-0 mt-1 text-center">
        {{ texto }}
      </p>
      <div class="w-100 mt-1">
        <button-look-feel [block]="true" color="outline" [compressed]="true" (clicked)="clicFileUpload()"><img
            src="assets/icons/lupa-azul.svg" alt=""
            style="width: 12px; margin-bottom: 3px; margin-right: 3px" />Selecciona o arrastra un
          archivo</button-look-feel>
        <input #fileInput type="file" accept=".png, .jpg, .pdf" (change)="onFileChange($event)" class="d-none" />
        <div class="d-block d-md-none mt-2">
          <p class="mb-0 text-center" *ngFor="let rule of rules">
            <span [innerHTML]="rule"></span>
          </p>
        </div>
        <div [ngClass]="isError?'':'invisible'">
          <app-advertencias [color]="'warning'" [compressed]="true" customClass="mb-0">El archivo supera el límite,
            comprímelo e intenta otra vez</app-advertencias>
        </div>
      </div>
    </div>
  </div>
</app-card-dotted-line>
