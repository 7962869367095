import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/proveiders/CustomValidators';
import { DataService } from 'src/app/services/data.service';
import { DialogService } from 'src/app/services/dialog.service';
import { GetInformacionService } from '../../services/perfilamiento/get-informacion.service';

@Component({
  selector: 'app-registro-celular',
  templateUrl: './registro-celular.component.html',
  styleUrls: ['./registro-celular.component.css'],
})
export class RegistroCelularComponent implements OnInit {
  url = '';
  public isValidConfirmar: boolean = false;
  private debounceTime?: NodeJS.Timeout;
  public formInfoTele: FormGroup;
  loading: boolean = false;
  intentosContinuar = 0;

  constructor(
    private router: Router,
    private dialogServ: DialogService,
    private data: DataService,
    private getinfoServ: GetInformacionService,
  ) {
    this.formInfoTele = new FormGroup({
      telefono: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10),
        CustomValidators.patternValidator(/\d+$/i, { onlyNumber: true }),
      ]),
      confirmarTelefono: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10),
        CustomValidators.patternValidator(/\d+$/i, { onlyNumber: true }),
      ]),
    });
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    this.dialogServ.openDialog(
      'Función no permitida',
      'Ingrese manualmente el número de celular',
      false
    );
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    this.dialogServ.openDialog(
      'Función no permitida',
      'No es permitido copiar el contenido seleccionado',
      false
    );
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    this.dialogServ.openDialog(
      'Función no permitida',
      'No es permitido cortar el contenido seleccionado',
      false
    );
    e.preventDefault();
  }

  ngOnInit() {
    throw new Error('Method not implemented.');
  }

  ngAfterViewInit(): void {
    const article = document.querySelector('article');
    if (article) {
      article.classList.remove('bg-blue-gradient-bottom');
    }
    this.url = this.router.url.split('/')[1];
  }

  ngOnDestroy() {
    const article = document.querySelector('article');
    if (article) {
      article.classList.add('bg-blue-gradient-bottom');
    }
  }

  modalAuto(message = '') {
    this.dialogServ.openDialog('Celular incorrecto', message, false, 'Volver a intentar');
  }

  guardarCelular(): any {
    const { telefono } = this.formInfoTele.value;

    this.data.isNewPhone = true;

    this.getinfoServ
      .saveCascadaPhone({
        ind: 2,
        celular: telefono,
        carpeta: this.data.numeroCarpeta.toString(),
        cedula: this.data.cedula.toString(),
      })
      .subscribe(
        async (resp) => {
          this.router.navigateByUrl('/' + resp.data.ruta);
        },
        (error) => {
          this.loading = false;
          this.intentosContinuar = 0;
          this.modalAuto(error.error.message);
        }
      );

    return true;
  }

  actualizarCelular(): any {
    this.data.isUpdateMobile = true;

    const { telefono } = this.formInfoTele.value;
    this.getinfoServ
      .changeNumberPhone({
        celular: telefono,
        carpeta: this.data.numeroCarpeta.toString(),
      })
      .subscribe(
        async () => {
          this.router.navigateByUrl('/validaotp');
        },
        (error) => {
          this.intentosContinuar = 0;
          console.log('Falle guradado de respuesta celular' + error);
        }
      );
    return true;
  }

  changeComfirmar(query) {
    const { telefono } = this.formInfoTele.value;

    if (this.debounceTime) clearTimeout(this.debounceTime);
    this.debounceTime = setTimeout(() => {
      if (telefono == query) {
        this.isValidConfirmar = false;
      } else {
        this.isValidConfirmar = true;
      }
    }, 500);
  }

  confirmacion() {
    this.loading = true;
    try {
      if (this.intentosContinuar != 0) {
        return;
      }
      this.intentosContinuar = 1;

      const { telefono } = this.formInfoTele.value;
      this.dialogServ.openDialog(
        "Confirmación",
        `¿Estás seguro que <strong>${telefono}</strong> es el número de celular que deseas registrar?`,
        true,
        "Si, guardar",
        () => {
          this.loading = true;
          this.url === 'modificarcelular' ? this.actualizarCelular() : this.guardarCelular();
        },
        true,
        "No, ajustar",
        () => {
          this.loading = false;
          this.intentosContinuar = 0;
        });
    } catch (error) {
      this.modalAuto(error.error.message);
      this.loading = false;
      this.intentosContinuar = 0;
    }
  }
}
