<div class="contenido" style="padding-bottom: 1rem">
  <h1>Validación de identidad</h1>
  <h2>Actualización de información del solicitante</h2>
  <p>
    Solicita la siguiente información para continuar proceso de autorización de tratamiento de datos y validación de
    identidad.
  </p>
  <form class="form" [formGroup]="formInfoTele" style="margin-left: -17px;">
    <div class="row">
      <div class="inp-wrap col-4 col-sm-4 col-md-4 col-lg-4 mb-err-inp">
        <mat-label>Teléfono celular</mat-label>
        <mat-form-field style="width: 103%" appearance="outline">
          <input matInput formControlName="telefono" maxlength="10" (keyup)="changeComfirmar(txtQuery.value)" />
          <mat-error style="color: #f44336;margin-left: -7px;" class="error"
            *ngIf="formInfoTele.controls.telefono.hasError('required')">
            El teléfono celular es obligatorio
          </mat-error>
          <mat-error style="color: #f44336;margin-left: -7px;" class="error"
            *ngIf="formInfoTele.controls.telefono.hasError('onlyNumber')">
            Solo se aceptan caracteres numéricos
          </mat-error>
          <mat-error style="color: #f44336;margin-left: -7px;" class="error"
            *ngIf="formInfoTele.controls.telefono.hasError('minlength')">
            El mínimo requerido son de 10 dígitos
          </mat-error>
        </mat-form-field>
      </div>
      <div class="inp-wrap col-4 col-sm-4 col-md-4 col-lg-4 mb-err-inp">
        <mat-label>Confirmar teléfono celular</mat-label>
        <mat-form-field style="width: 109%" appearance="outline">
          <input matInput formControlName="confirmarTelefono" #txtQuery (keyup)="changeComfirmar(txtQuery.value)"
            maxlength="10" />
          <mat-error style="color: #f44336;margin-left: -7px;" class="error"
            *ngIf="formInfoTele.controls.confirmarTelefono.hasError('required')">
            El teléfono celular es obligatorio
          </mat-error>
          <mat-error style="color: #f44336;margin-left: -7px;" class="error" *ngIf="
              formInfoTele.controls.confirmarTelefono.hasError('onlyNumber')
            ">
            Solo se aceptan caracteres numéricos
          </mat-error>
          <mat-error style="color: #f44336;margin-left: -7px;" class="error" *ngIf="
              formInfoTele.controls.confirmarTelefono.hasError('minlength')
            ">
            El mínimo requerido son de 10 dígitos
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="alert" *ngIf="isValidConfirmar">
    <img src="../../../assets/icons/errorClic.svg" alt="error clic" />
    No coinciden los teléfonos celulares ingresados
  </div>
  <br>
  <div id="autorizacion">
    Al ingresar los datos personales del solicitante, como asesor/corredor declaras que cuentas con la autorización para
    transferir estos datos personales a Bayport y cuentas con los consentimientos necesarios para
    realizar el tratamiento de datos personales, en calidad de responsable de
    acuerdo con las obligaciones establecidas para dicho rol por la
    <b>Ley 1581 de 2012</b> y sus decretos reglamentarios.
  </div><br><br><br><br>
  <button class="continuar" (click)="confirmacion()" [disabled]="!formInfoTele.valid || isValidConfirmar || loading"
    mat-button>
    <mat-icon *ngIf="loading">
      <mat-spinner diameter="25"></mat-spinner>
    </mat-icon>
    Guardar
  </button>
</div>
