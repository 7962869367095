import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from '@modules/shared/services/dialog.service';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService as DialogService2 } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-load-docs-card',
  templateUrl: './load-docs-card.component.html',
  styleUrls: ['./load-docs-card.component.css']
})
export class LoadDocsCardComponent implements OnInit, OnDestroy {
  @Input('titulo') titulo:string;
  @Input('type') type: 'load' | 'common' | 'pdf' = 'load';
  @Input('allowedFormats') allowedFormats: string = 'pdf';
  @Input('text') text:string = `Carga el archivo en formato ${this.allowedFormats.toUpperCase()} de máximo 5 mb. Si el documento tiene más de una página, deben estar en el mismo archivo.`;
  @Input('enableCamera') enableCamera: boolean = true;
  @Output('charged') isCharged: EventEmitter<boolean> = new EventEmitter();
  @Output('chargedFile') chargedFile: EventEmitter<{file_name:string, file_ext:string, file_base64:string}> = new EventEmitter();

  public paso1 = true;
  public paso2 = false;
  public viewpdf = false;
  src = '';
  isPdfLoaded = false;
  fileUrl;

  private pdf: PDFDocumentProxy;

  pdfSource = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";


  private subList: Array<Subscription> = [];

  constructor(
    private _dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService2,
    private render: Renderer2
  ) {   }

  ngOnInit(): void {

    this.subList.push(
      this._dialogService.isLoaded.subscribe(
        res=>{
          if(res) {
            this.setLoadStatus('onLoad');
          } else {
            this.setLoadStatus('loaded');
          }
        }
      )
    );
  }
  ngOnDestroy(): void {
    this.subList.forEach(item=>item.unsubscribe());
  }

  onAddFile({ target: { files } }: any): void {
    const { name } = files[0];
    const { size } = files[0];
    console.log(files[0]);

    const name_split = name.split('.');
    const fileSize = Math.round(size/1024);

    if (this.paso1 && fileSize <= 5000 && name.split('.')[1].toLowerCase() === 'pdf') {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = ()=>{
        this.setLoadStatus('loaded',{file_name: name_split[0],file_ext: name_split[1],file_base64: (reader.result as string).split(',')[1]});
      }
    } else {
      if(fileSize > 5000 ) {
        this.dialogService.openDialog(
          'Peso máximo superado',
          'El peso de archivo supera lo permitido, comprime el archivo y vuelve a intentarlo.',
          false,
          'Carga otro archivo',
          ()=>{
            this.setLoadStatus('onLoad');
          }
        )
      }
      else {
        this.dialogService.openDialog(
          'El archivo no tiene el formato permitido',
          'El archivo seleccionado debe estar en formato PDF.',
          false,
          'Carga otro archivo',
          ()=>{
            this.setLoadStatus('onLoad');
          }
        )
      }
      this.setLoadStatus('onLoad');
    }
  }

  eliminarArchivo(){
    if (this.paso2) {
      this.setLoadStatus('onLoad');
    }
  }

  private setLoadStatus(status:'onLoad' | 'loaded' | 'view', file?: {file_name:string, file_ext: string, file_base64:string}): void {
    if( status === 'onLoad') {
      this.paso1 = true;
      this.paso2 = false;
      this.isCharged.emit(false);
      this.chargedFile.emit({
        file_name:null,
        file_ext:null,
        file_base64:null
      })
    } else if(status === 'loaded'){
      this.paso1 = false;
      this.paso2 = true;
      this.isCharged.emit(true);
      this.chargedFile.emit(file);
    }
  }

  onLoaded(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.isPdfLoaded = true;
  }

  viewp(){
    this.paso1 = false;
      this.paso2 = false;
      this.viewpdf = true;
  }

  onDownload() {
     const data = this.pdfSource;
     const blob = new Blob([data], { type: 'application/octet-stream' });
     this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }
}
