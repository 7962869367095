<app-main-container header="Análisis de solicitud del crédito ">
  <div class="p-5 ">
    <h5>Este proceso es importante para la verificación de nuestro cliente, se
      requiere alguna información por parte del cliente para finalizar este
      proceso.</h5><br />
    <div class="row mt-3">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="nplan sdow" style="
                    width: 203px;
                    height: 31px;
                    font-size: 18px;
                    letter-spacing: 0px;
                  " scope="col">
                <strong>Política</strong>
              </th>
              <th style="width: 617px; height: 31px; font-size: 18px">
                <strong>Accciones a ejecutar</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dato of dataSource; let i = index" style="cursor: pointer">
              <th [class]="i == dataSource.length - 1 ? 'border' : ''" style="
                    width: 50px;
                    background-color: white;
                    border-bottom: 1px solid white;
                  " class="right left sdow">
                {{ dato.NOMBREPOLITICA }}
              </th>
              <td style="width: 70px; border-left: none !important">
                {{ dato.RESTRICCION }}
              </td>

              <!--  <td style="width: 50px;"><div class="seguro"><input [value]="dato.codigoPlan" [(ngModel)]='planseleccionado' name="inlineRadioOptions" type="radio"></div></td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col t-end">
        <div class="botones">
          <button class="btn-cam" mat-button (click)="continuar()">
            <strong>Continuar</strong>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-main-container>
