<!--app-header></app-header-->
<div>
  <br /><br />
  <div class="circle blue" *ngIf="estado !== 'resultados' && estado !== 'detalles'"></div>
  <div id="busqueda" *ngIf="estado == 'busqueda'">
    <h1 class="titulo mb-0 pb-0" style="padding-bottom: 1%">
      Búsqueda de carpetas
    </h1>
    <h3 class="subtitulo fw-semibold">
      Selecciona el criterio por el que deseas ubicar la carpeta
    </h3>
    <br /><br />
    <div class="row">
      <div class="col-md-12 capa1" align="center">
        <div id="tabsN">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#cedula" role="button"
                aria-controls="home" aria-selected="true">Cédula</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#fecha" role="button"
                aria-controls="profile" aria-selected="false">Fecha de creación</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#pagaduria" role="button"
                aria-controls="contact" aria-selected="false">Pagaduría</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#nombre" role="button"
                aria-controls="contact" aria-selected="false">Nombre</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#apellido" role="button"
                aria-controls="contact" aria-selected="false">Apellido</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#carpeta" role="button"
                aria-controls="contact" aria-selected="false">Número de carpeta</a>
            </li>
          </ul>
        </div>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="cedula" role="tabpanel" aria-labelledby="cedula-tab">
            <section class="mt-1 flex justify-content-center">
              <article class="bg-blue-gradient w-1/2 px-6 sm:py-6">
                <div class="flex flex-column gap-2 mb-2">
                  <label class="text-white m-0 labelBC">
                    <strong  > Cédula </strong>
                  </label>
                  <input pInputText placeholder="*número de cédula" class="p-inputtext-sm" [(ngModel)]="entradabusqueda"
                    (ngModelChange)="entradabusqueda = $event.toUpperCase()" />
                </div>
                <div class="px-6 flex justify-content-center">
                  <button pButton (click)="buscar('cedula')" type="submit" [disabled]="searching"
                    class="px-6 py-2 mt-4 text-gray-900 bg-sky-200 border-none buscar">
                    <span *ngIf="searching" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    Buscar
                  </button>
                </div>
              </article>
            </section>
          </div>
          <div class="tab-pane fade" id="fecha" role="tabpanel" aria-labelledby="fecha-tab">
            <section class="mt-1 flex justify-content-center">
              <article class="bg-blue-gradient w-1/2 px-6 sm:py-6">
                <div class="flex flex-column gap-2 mb-2">
                  <label class="text-white m-0 labelBC">
                    <strong > Fecha de inicio </strong>
                  </label>
                  <div class="input-group">
                    <input class="p-inputtext-sm" pInputText name="dp" placeholder="aaaa/mm/dd"
                      [(ngModel)]="fechaInicial" ngbDatepicker #d="ngbDatepicker" [placement]="placement"
                      [positionTarget]="buttonEl" style="width: 360px" (click)="d.toggle()" />
                    <button #buttonEl class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()"
                      type="button">
                      <i class="bi bi-calendar"></i>
                    </button>
                  </div>
                </div>
                <div class="flex flex-column gap-2 mb-2">
                  <label class="text-white m-0 labelBC">
                    <strong > Fecha de finalización </strong>
                  </label>
                  <div class="input-group">
                    <input class="p-inputtext-sm" pInputText placeholder="aaaa/mm/dd" name="dp" [(ngModel)]="fechaFinal"
                      ngbDatepicker [dayTemplate]="customDay" [markDisabled]="isDisabled" #df="ngbDatepicker"
                      style="width: 360px" (click)="df.toggle()" />
                    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="df.toggle()" type="button">
                      <i class="bi bi-calendar"></i>
                    </button>
                  </div>

                  <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                    let-disabled="disabled" let-focused="focused">
                    <span class="custom-day" [class.weekend]="isWeekend(date)" [class.focused]="focused"
                      [class.bg-primary]="selected" [class.hidden]="date.month !== currentMonth"
                      [class.text-muted]="disabled">
                      {{ date.day }}
                    </span>
                  </ng-template>
                  <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                    let-disabled="disabled" let-focused="focused">
                    <span class="custom-day" [class.weekend]="isWeekend(date)" [class.focused]="focused"
                      [class.bg-primary]="selected" [class.hidden]="date.month !== currentMonth"
                      [class.text-muted]="disabled">
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>

                <div class="px-6 flex justify-content-center">
                  <button pButton type="submit" [disabled]="searching" (click)="buscar('fecha')"
                    class="px-6 py-2 mt-4 text-gray-900 bg-sky-200 border-none buscar">
                    <span *ngIf="searching" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    Buscar
                  </button>
                </div>
              </article>
            </section>
          </div>
          <div class="tab-pane fade" id="pagaduria" role="tabpanel" aria-labelledby="pagaduria-tab">
            <section class="mt-1 flex justify-content-center">
              <article class="bg-blue-gradient w-1/2 px-6 sm:py-6">
                <div class="flex flex-column gap-2 mb-2">
                  <label class="text-white m-0 labelBC">
                    <strong > Convenio </strong>
                  </label>
                  <select (change)="getPagadurias(this.convenio)" class="form-select p-inputtext-sm" pInputText
                    aria-label="Default select example" [(ngModel)]="convenio" required style="width: 360px">
                    <option *ngFor="let item of filteredConvenio" [value]="item.codigo">
                      {{ item.nombre }}
                    </option>
                  </select>
                </div>
                <div class="flex flex-column gap-2 mb-2">
                  <label class="text-white m-0 labelBC">
                    <strong > Pagaduría </strong>
                  </label>
                  <select class="form-select" pInputText aria-label="Default select example" [(ngModel)]="pagaduria">
                    <option *ngFor="let item of filteredPagaduria" [value]="item.codigoPagaduria">
                      {{ item.nombre }}
                    </option>
                  </select>
                </div>
                <div class="px-6 flex justify-content-center">
                  <button pButton type="submit" (click)="buscar('pagaduria')" [disabled]="searching"
                    class="px-6 py-2 mt-4 text-gray-900 bg-sky-200 border-none buscar">
                    <span *ngIf="searching" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    Buscar
                  </button>
                </div>
              </article>
            </section>
          </div>
          <div class="tab-pane fade" id="nombre" role="tabpanel" aria-labelledby="nombre-tab">
            <section class="mt-1 flex justify-content-center">
              <article class="bg-blue-gradient w-1/2 px-6 sm:py-6">
                <div class="flex flex-column gap-2 mb-2">
                  <label class="text-white m-0 labelBC">
                    <strong > Nombre </strong>
                  </label>
                  <input pInputText placeholder="*nombre" class="p-inputtext-sm" [(ngModel)]="entradabusqueda"
                    (ngModelChange)="entradabusqueda = $event.toUpperCase()" />
                </div>
                <div class="px-6 flex justify-content-center">
                  <button pButton type="submit" (click)="buscar('nombre')" [disabled]="searching"
                    class="px-6 py-2 mt-4 text-gray-900 bg-sky-200 border-none buscar">
                    <span *ngIf="searching" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    Buscar
                  </button>
                </div>
              </article>
            </section>
          </div>
          <div class="tab-pane fade" id="apellido" role="tabpanel" aria-labelledby="apellido-tab">
            <section class="mt-1 flex justify-content-center">
              <article class="bg-blue-gradient w-1/2 px-6 sm:py-6">
                <div class="flex flex-column gap-2 mb-2">
                  <label class="text-white m-0 labelBC">
                    <strong > Apellido </strong>
                  </label>
                  <input pInputText placeholder="*apellido" class="p-inputtext-sm" [(ngModel)]="entradabusqueda"
                    (ngModelChange)="entradabusqueda = $event.toUpperCase()" />
                </div>
                <div class="px-6 flex justify-content-center">
                  <button pButton label="Buscar" type="submit" (click)="buscar('apellido')"
                    class="px-6 py-2 mt-4 text-gray-900 bg-sky-200 border-none buscar"></button>
                </div>
              </article>
            </section>
          </div>
          <div class="tab-pane fade" id="carpeta" role="tabpanel" aria-labelledby="carpeta-tab">
            <section class="mt-1 flex justify-content-center">
              <article class="bg-blue-gradient w-1/2 px-6 sm:py-6">
                <div class="flex flex-column gap-2 mb-2">
                  <label class="text-white m-0 labelBC">
                    <strong > Número de carpeta </strong>
                  </label>
                  <input pInputText placeholder="*número de carpeta" class="p-inputtext-sm"
                    [(ngModel)]="entradabusqueda" (ngModelChange)="entradabusqueda = $event.toUpperCase()" />
                </div>
                <div class="px-6 flex justify-content-center">
                  <button pButton label="Buscar" type="submit" (click)="buscar('numcarpeta')"
                    class="px-6 py-2 mt-4 text-gray-900 bg-sky-200 border-none buscar"></button>
                </div>
              </article>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="resultados" class="p-4" *ngIf="estado == 'resultados'" class="container">
    <div>
      <div class="row titulo1">
        <div class="col-md-12">
          <div (click)="estado = 'busqueda'" class="regresar volver" role="button">
            <i _ngcontent-hop-c155="" class="fa fa-arrow-right rotate-180"></i>
            Volver
          </div>
          <div class="titulo " style="text-align: center">
            Resultado de búsqueda
          </div>
        </div>
      </div>
      <br />
      <div class="row resultado">
        <div class="col-md-4" *ngFor="
            let result of resultadosBusqueda
              | paginator : page_size : pageNumber
          ">
          <app-detalle-carpeta [datosCarpeta]="result">
            <div footer class="row">
              <div class="col-md-5">
                <button class="btnDeta btn" (click)="estado = 'detalles'; resultadoDetalle = result"
                  style="width: 100%">
                  Ver Detalles
                </button>
              </div>
              <div class="col-md-7">
                <button class="btnRet btn" (click)="goStep(result)" style="width: 100%">
                  <mat-icon *ngIf="isLoading">
                    <!-- <mat-icon> -->
                    <mat-spinner diameter="20"></mat-spinner>
                  </mat-icon>
                  Retomar carpeta
                </button>
              </div>
            </div>
          </app-detalle-carpeta>
        </div>
        <mat-paginator [length]="total_pages" [pageSize]="page_size" [pageSizeOptions]="pageSizeOptions"
          (page)="handlePage($event)">
        </mat-paginator>
      </div>
      <br />

      <!-- <ngb-pagination [collectionSize]="page_size"   />
      <hr />

      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li class="page-item"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav> -->

      <!-- <mat-paginator [length]="total_pages" [pageSize]="page_size" [pageSizeOptions]="pageSizeOptions"
        (page)="handlePage($event)">
      </mat-paginator> -->
    </div>
  </div>

  <div class="row" id="detalles" *ngIf="estado == 'detalles'">

      <div class="row titulo1">
        <div class="col-md-12">
          <div (click)="estado = 'resultados'" class="regresar volver" role="button">
            <i _ngcontent-hop-c155="" class="fa fa-arrow-right rotate-180"></i>
            Volver
          </div>
          <div class="titulo  " style="text-align: center">
            Resumen del crédito
          </div>
        </div>
      </div>
      <!-- <br /><br /> -->
      <app-main-container>
      <!-- <div class="resultado1" style="background: #fff; width: 100%"> -->
        <div class="row" style="padding: 0%;">
          <div class="col-md-3 border-info border-right-1">
            <label class="labelT">Nombre:</label>
            <label class="labelT" style="display: inline-block"><strong>{{ resultadoDetalle.nombreCliente
                }}</strong></label>
          </div>
          <div class="col-md-3 border-info border-right-1">
            <label class="labelT">Número de carpeta</label>
            <label class="labelT" style="display: inline-block"><strong>{{ resultadoDetalle.numeroCarpeta
                }}</strong></label>
          </div>
          <div class="col-md-3 border-info border-right-1">
            <label class="labelT">Monto</label>
            <label class="labelT" style="display: inline-block"><strong>{{
                resultadoDetalle.montoAprobado | currency
                }}</strong></label>
          </div>
          <div class="col-md-3" [style]="{
            background: resultadoDetalle.semaforo['TBC_COLOR'],
            width: '25%',
            'border-radius': '0%'
          }">
            <!-- <img src="../../../../assets/icons/Gris.svg"> -->
            <div [innerHTML]="estadoIcono"></div>
            <label class="labelT">Estado actual de la carpeta</label>
            <label class="labelT"><strong>{{ resultadoDetalle.estado }} </strong></label>
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-md-12" align="center">
            <ul class="nav nav-tabs" id="myTab1" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#cedula" role="button"
                  aria-controls="home" aria-selected="true">Información general</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#fecha" role="button"
                  aria-controls="profile" aria-selected="false">Motor de decisión</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#pagaduria" role="button"
                  aria-controls="contact" aria-selected="false">Comprobantes de compra de cartera</a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent1">
              <div class="tab-pane fade show active" id="cedula" role="tabpanel" aria-labelledby="cedula-tab">
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <td>
                        <strong>N.M.V:tasa de interés corriente nóminal mensual
                          vencido</strong><br />
                        Nominal mensual vencido
                      </td>
                      <td>{{ resultadoDetalle.tasaAprobadanominal }}%</td>
                      <td><strong>Comisión por millón</strong></td>
                      <td>{{ resultadoDetalle.valorMillon | currency }}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>E.A: Tasa de interés corriente efectiva anual</strong><br />
                        Efectivo anual
                      </td>
                      <td>
                        {{ resultadoDetalle.tasaAprobadaefectiva | currency }}%
                      </td>
                      <td><strong>Monto</strong></td>
                      <td>
                        {{ resultadoDetalle.montoAprobadoAnalista | currency }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>N.M.V: tasa de interés máxima legal permitida</strong><br />
                        Nominal mensual vencido
                      </td>
                      <td>{{ resultadoDetalle.tasaUsuraMv | currency }}%</td>
                      <td><strong>Plazo</strong></td>
                      <td>{{ resultadoDetalle.plazoAprobadoAnalista }} Meses</td>
                    </tr>
                    <tr>
                      <td><strong>Seguro de vida deudores</strong></td>
                      <td>{{ resultadoDetalle.seguroVida }}%</td>
                      <td><strong>Cuota</strong></td>
                      <td>
                        {{ resultadoDetalle.cuotaAprobadaAnalista | currency }}
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Plan Poliza</strong></td>
                      <td>
                        {{
                        resultadoDetalle.planSeguroVoluntario | seguroVoluntario
                        }}
                      </td>
                      <td>
                        <strong>Estudio de crédito y administración</strong>
                      </td>
                      <td>{{ genOf.estudioCredito }}%</td>
                    </tr>
                    <tr>
                      <td><strong>Valor total asegurado</strong></td>
                      <td>{{ resultadoDetalle.polizaValorPlan | currency }}</td>
                      <td><strong>Pagaduría</strong></td>
                      <td>{{ resultadoDetalle.pagaduria }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-pane fade" id="fecha" role="tabpanel" aria-labelledby="fecha-tab">
                <table class="table table-striped">
                  <tbody>
                    <tr *ngFor="let dato of dataSource; let i = index">
                      <td>
                        <strong>{{ dato.NOMBREPOLITICA }}</strong>
                      </td>
                      <td style="border-left: none !important">
                        {{ dato.RESTRICCION }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-pane fade" id="pagaduria" role="tabpanel" aria-labelledby="pagaduria-tab">
                <mat-accordion>
                  <mat-expansion-panel *ngFor="let file of listFiles" (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                      <mat-panel-title> {{ file.fileName }} </mat-panel-title>
                    </mat-expansion-panel-header>
                    <section style="width: 100%; background-color: #dbdbdb">
                      <section style="
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        width: 100%;
                        padding: 1rem;
                        gap: 2rem;
                        box-shadow: 0px 3px 15px #00000029;
                        height: 61px;
                      ">
                        <div style="display: flex; gap: 0.5rem">
                          <img src="./../../../assets/icons/siguiente-pagina.svg" alt=""
                            style="transform: rotate(180deg)" (click)="anteriorPagina()" />
                          <img src="./../../../assets/icons/siguiente-pagina.svg" alt="" (click)="siguientePagina()" />
                          <p style="
                            padding: 0 !important;
                            margin: 0 !important;
                            font-size: 14px !important;
                          ">
                            Page {{ page }} /
                            {{ pdfViewer?.pdfViewer?.pagesCount }}
                          </p>
                        </div>
                        <div style="display: flex; gap: 0.5rem">
                          <img src="./../../../assets/icons/mas-zoom.svg" alt="" (click)="menosZoom()" />
                          <img src="./../../../assets/icons/menos-zoom.svg" alt="" (click)="masZoom()" />
                          <p style="
                            padding: 0 !important;
                            margin: 0 !important;
                            font-size: 14px !important;
                          ">
                            Zoom 50%
                          </p>
                        </div>
                        <div>
                          <img src="./../../../assets/icons/descargar.svg" alt="" (click)="
                            downloadPDF(
                              'data:application/pdf;base64,' + file.fileBase64,
                              file.fileName
                            )
                          " />
                        </div>
                      </section>
                      <div style="padding: 3rem">
                        <pdf-viewer [src]="'data:application/pdf;base64,' + file.fileBase64" [render-text]="true"
                          [original-size]="false" [show-all]="false" [page]="page" [zoom]="zoom"
                          style="width: 100%; height: 500px"></pdf-viewer>
                      </div>
                    </section>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
            <br /><br /><br />
            <div class="row foot">
              <div class="col-md-10" style="text-align: left">
                ¿El solicitante no recibió el mensaje o correo para la firma de
                documentos?
                <a (click)="resetMessage()" style="font-weight: 900; cursor: pointer; color: #009bdc">
                  Haz clic aquí
                </a>
              </div>
              <div class="col-md-2">
                <button class="continue" mat-button (click)="goStep(resultadoDetalle)">
                  Retomar carpeta
                </button>
              </div>
            </div>
            <br />
          </div>
        </div>
      <!-- </div> -->
    </app-main-container>
  </div>
</div>
