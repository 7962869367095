import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { TinduxAuthService } from 'src/app/services/tindux/tindux-auth.service';

@Component({
  selector: 'app-capacitacion-page',
  templateUrl: './capacitacion-page.component.html',
  styleUrls: ['./capacitacion-page.component.css']
})
export class CapacitacionPageComponent implements OnInit {
  public loadSpinner: boolean = false

  constructor(
    private _tinduxAuthService: TinduxAuthService,
    public dialogService: DialogService
  ) { }

  ngOnInit(): void {
  }

  onContinue(): void {
    this.loadSpinner = true;
    let { usuario } = JSON.parse(localStorage.getItem('credentials'));
    const body = {
      site: "bayportCli",
      tipo: "CC",
      numIdent: usuario
    }
    this._tinduxAuthService.auth(body).subscribe(
      res => {
        const { CODE } = res;
        if (CODE === 200) {
          localStorage.clear();
          location.href = res.data.url;
        } else {
          this.loadSpinner = false;

          this.dialogService.openDialog("Error",'⚠️ '+res.MESSAGE+'. (code: ' + res.CODE + ')',false)
        }
      },
      err => {
        this.loadSpinner = false;
        this.dialogService.openDialog("Error",'⚠️ '+err.error.message+'. (code: ' + err.status + ')',false)
      }
    );
  }
}
