import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CdTimerModule } from 'angular-cd-timer';
import { CodeInputModule } from 'angular-code-input';
import { WebcamModule } from 'ngx-webcam';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExpiredPasswordComponent } from './v2/pages/auth/expired-password/expired-password.component';
import { AutoCentralComponent } from './auto-central/auto-central.component';
import { ValidacionComponent } from './validacion/validacion.component';
import { ComponentsModule } from './components/components.module';
import { DialogComponent } from './components/dialogs/standar/dialog.component';
import { MaterialModule } from './material.module';
import { BusquedaCarpetasComponent } from './pwa/busqueda-carpetas/busqueda-carpetas.component';
import { DetalleCarpetaComponent } from './pwa/busqueda-carpetas/detalle-carpeta/detalle-carpeta.component';
import { CargaDocumentosComponent } from './pwa/carga-documentos/carga-documentos.component';
import { FormularioComponent } from './pwa/formulario/formulario.component';
import { HomeComponent } from './pwa/home/home.component';
import { InformacionAdicionalComponent } from './pwa/informacion-adicional/informacion-adicional.component';
import { MontoComponent } from './pwa/monto/monto.component';
import { AutorizacionTransferenciaComponent } from './pwa/pre-autorizacion/autorizacion-transferencia/autorizacion-transferencia.component';
import { TerminosAutorizacionComponent } from './pwa/pre-autorizacion/components/terminos-autorizacion/terminos-autorizacion.component';
import { DatosClienteComponent } from './pwa/pre-autorizacion/datos-cliente/datos-cliente.component';
import { PreAutorizacionComponent } from './pwa/pre-autorizacion/pre-autorizacion.component';
import { DialogModificaComponent } from './pwa/pre-oferta/dialog-modifica/dialog-modifica.component';
import { DialogRechazaComponent } from './pwa/pre-oferta/dialog-rechaza/dialog-rechaza.component';
import { PreOfertaComponent } from './pwa/pre-oferta/pre-oferta.component';
import { DialogImportComponent } from './pwa/rob-analisis/dialog-import/dialog-import.component';
import { RobAnalisisComponent } from './pwa/rob-analisis/rob-analisis.component';
import { SeguroVoluntarioComponent } from './pwa/seguro-voluntario/seguro-voluntario.component';
import { JwtInterceptor } from './services/interceptor';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ProSolicitudComponent } from './pwa/firma/pro-solicitud/pro-solicitud.component';
import { NotificacionComponent } from './pwa/notificaciones/components/notificacion/notificacion.component';
import { SendComponent } from './pwa/notificaciones/components/send/send.component';
import { SimpleMessageComponent } from './pwa/notificaciones/components/simple-message/simple-message.component';
import { NotificacionesComponent } from './pwa/notificaciones/notificaciones.component';
import { ReporteriaComponent } from './pwa/reporteria/reporteria.component';
import { EliminarComponent } from './pwa/seguro-voluntario/components/eliminar/eliminar.component';
import { PazysalvoComponent } from './pwa/certificados/pazysalvo/pazysalvo.component';
import { SaldoComponent } from './pwa/certificados/saldo/saldo.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { ListadoCelularesComponent } from './pwa/pre-autorizacion/listado-celulares/listado-celulares.component';
import { RegistroCelularComponent } from './pwa/registro-celular/registro-celular.component';
import { RegistroCorreoComponent } from './pwa/registro-correo/registro-correo.component';
import { ValidarCorreoComponent } from './pwa/validar-correo/validar-correo.component';
import { MatBadgeModule } from '@angular/material/badge';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CambiaPasswordComponent } from './pwa/cambia-password/cambia-password.component';
import { CapacitacionPageComponent } from './pwa/capacitacion-page/capacitacion-page.component';
import { AceptacionFirmaComponent } from './pwa/certificados/aceptacion-firma/aceptacion-firma.component';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { FirmaDocComponent } from './pwa/certificados/firma-doc/firma-doc.component';
import { ValidAdoTermComponent } from './pwa/firma/valid-ado-term/valid-ado-term.component';
import { ComfirModelComponent } from './pwa/notificaciones/components/comfir-model/comfir-model.component';
import { ModalComponent } from './pwa/notificaciones/components/modal/modal.component';
import { SharedModule } from '@modules/shared/shared.module';
import { NgIdleModule } from '@ng-idle/core';
import { MiPerfilPageComponent } from './pwa/mi-perfil-page/mi-perfil-page.component';

// import Modules of PrimeNg
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

// Versión 2
import { CardFileUploadBancariaComponent } from './components/card-file-upload-bancaria/card-file-upload-bancaria.component';
import { AppV2Module } from './v2/app.v2.module';



export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 0,
  prefix: '$ ',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};

@NgModule({
  declarations: [
    MiPerfilPageComponent,
    AppComponent,
    FormularioComponent,
    BusquedaCarpetasComponent,
    DetalleCarpetaComponent,
    SeguroVoluntarioComponent,
    PreOfertaComponent,
    RobAnalisisComponent,
    AutoCentralComponent,
    ValidacionComponent,
    MontoComponent,
    TerminosAutorizacionComponent,
    PreAutorizacionComponent,
    AutorizacionTransferenciaComponent,
    DatosClienteComponent,
    DialogComponent,
    DialogImportComponent,
    DialogModificaComponent,
    DialogRechazaComponent,
    CargaDocumentosComponent,
    MainContainerComponent,
    HomeComponent,
    InformacionAdicionalComponent,
    NotificacionesComponent,
    ExpiredPasswordComponent,
    NotificacionComponent,
    SendComponent,
    SimpleMessageComponent,
    ReporteriaComponent,
    EliminarComponent,
    ProSolicitudComponent,
    SaldoComponent,
    PazysalvoComponent,
    AceptacionFirmaComponent,
    FirmaDocComponent,
    ValidAdoTermComponent,
    ModalComponent,
    ComfirModelComponent,
    CambiaPasswordComponent,
    CapacitacionPageComponent,
    RegistroCelularComponent,
    RegistroCorreoComponent,
    ValidarCorreoComponent,
    ListadoCelularesComponent
  ],
  imports: [
    // V2
    AppV2Module,
    PdfViewerModule,
    // Legacy
    ButtonModule,
    PasswordModule,
    CardModule,
    InputTextModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgbModule,
    FormsModule,
    MatAutocompleteModule,
    HttpClientModule,
    WebcamModule,
    CdTimerModule,
    ComponentsModule,
    MatBadgeModule,
    CodeInputModule.forRoot({
      codeLength: 6,
      isCharsCode: true,
      code: '',
    }),
    NgxMatSelectSearchModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true,
    }),
    MatSelectFilterModule,
    PdfViewerModule,
    NgIdleModule.forRoot(),
    SharedModule,
    CardFileUploadBancariaComponent,
  ],
  exports: [MaterialModule],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es-Es' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
