import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-detalle-carpeta',
  templateUrl: './detalle-carpeta.component.html',
  styleUrls: ['./detalle-carpeta.component.css']
})
export class DetalleCarpetaComponent implements OnInit {

  @Input() datosCarpeta: any;


  estadoColor: any;
  estadoIcono: SafeHtml;
  svgContainer1: any;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.estadoIcono = this.sanitizer.bypassSecurityTrustHtml(this.datosCarpeta.semaforo.ICON)
    this.estadoColor = this.datosCarpeta.semaforo.TBC_COLOR
  }
}
