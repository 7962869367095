import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DialogService } from '../../services/dialog.service';
import { DialogService as DialogService2 } from 'src/app/services/dialog.service';
import { PdfViewDialogComponent } from '../pdf-view-dialog/pdf-view-dialog.component';

@Component({
  selector: 'app-download-cert-ingreso-ret',
  templateUrl: './download-cert-ingreso-ret.component.html',
  styleUrls: ['./download-cert-ingreso-ret.component.css'],
})
export class DownloadCertIngresoRetComponent implements OnInit {
  @Input('titulo') titulo: string;
  @Input('type') type: 'load' | 'common' | 'pdf' = 'load';
  @Input('allowedFormats') allowedFormats: string = '.png, .jpg, .pdf';
  @Input('text')
  text: string = `Puedes subir ${this.allowedFormats.toUpperCase()} o utilizar tu teléfono móvil para tomar una fotografía`;
  @Input('enableCamera') enableCamera: boolean = true;
  @Input('base64') base64: string = '';
  @Input('file-name') file_name: string = '';
  @Output('charged') isCharged: EventEmitter<boolean> = new EventEmitter();
  @Input() mesCargado = '';
  @Input() isComision = true;

  public paso1 = true;
  public paso2 = false;
  public viewpdf = false;
  fileUrl;

  private subList: Array<Subscription> = [];

  constructor(
    private _dialogService: DialogService,
    private dialogService: DialogService2,
    private dialog: MatDialog
  ) { }
  ngOnInit(): void {
    this.subList.push(
      this._dialogService.isLoaded.subscribe((res) => {
        if (res) {
          this.setLoadStatus('onLoad');
        } else {
          this.setLoadStatus('loaded');
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subList.forEach((item) => item.unsubscribe());
  }

  onAddFile({ target: { files } }: any): void {
    const { name } = files[0];
    const { size } = files[0];
    const fileSize = size * 0.001;
    if (
      this.paso1 &&
      fileSize <= 5000 &&
      name.split('.')[1].toLowerCase() === 'pdf'
    ) {
      this.setLoadStatus('loaded');
    } else {
      if (fileSize > 5000) {
        this.dialogService.openDialog(
          'Peso máximo superado',
          'El peso de archivo supera lo permitido, comprime el archivo y vuelve a intentarlo.',
          false,
          'Carga otro archivo',
          () => {
            this.setLoadStatus('onLoad');
          }
        )
      } else {
        this.dialogService.openDialog(
          'El archivo no tiene el formato permitido',
          'El archivo seleccionado debe estar en formato PDF.',
          false,
          'Carga otro archivo',
          () => {
            this.setLoadStatus('onLoad');
          }
        )
      }
      this.setLoadStatus('onLoad');
    }
  }

  eliminarArchivo() {
    if (this.paso2) {
      this.setLoadStatus('onLoad');
    }
  }

  private setLoadStatus(status: 'onLoad' | 'loaded' | 'view'): void {
    if (status === 'onLoad') {
      this.paso1 = true;
      this.paso2 = false;
      this.isCharged.emit(false);
    } else if (status === 'loaded') {
      this.paso1 = false;
      this.paso2 = true;
      this.isCharged.emit(true);
    }
  }

  viewp() {
    const config: MatDialogConfig = {
      width: '100%',
      maxWidth: '500px',
      height: 'fit-content',
      panelClass: 'no-padding',
      data: {
        document_base64: this.base64,
      },
    };
    this.dialog.open(PdfViewDialogComponent, config);
  }

  onDownload() {
    const b64 = this.base64;
    const linkSource = `data:application/pdf;base64,${b64}`;
    const a = document.createElement('a');
    const fileName = this.file_name;
    a.href = linkSource;
    a.download = fileName;
    a.click();
  }
}
