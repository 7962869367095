import { Component } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RcEnvioOTPService } from 'src/app/services/recuperacion/rc-envio-otp.service';
import { RcSolicitudService } from 'src/app/services/recuperacion/rc-solicitud.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}


@Component({
  selector: 'app-recuperar-contrasena',
  templateUrl: './recuperar-contrasena.component.html',
  styleUrls: ['./recuperar-contrasena.component.css']
})
export class RecuperarContrasenaComponent {
  recForm: UntypedFormGroup;
  hide: boolean = true;
  errorLog: string = '';
  matcher = new MyErrorStateMatcher();
  public error: string | null = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private rcEnvioOpt: RcEnvioOTPService,
    private api: RcSolicitudService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.recForm = new UntypedFormGroup({
      user: new UntypedFormControl(null, [
        Validators.pattern('^\\d+$'),
        Validators.minLength(1),
        Validators.maxLength(15),
        Validators.required,
      ]),
    });
  }

  ngOnInit(): void { }

  //aqui va el service
  recuperarPassword() {
    const { user: usuario } = this.recForm.value;
    this.api.recoveryPasword(usuario).subscribe(
      (resp) => {
        this.router.navigate(['/auto-central']);
      },
      (error) => {
        this.error = error.error.message;
      }
    );
  }
}
